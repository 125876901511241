/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Button, Icon, Input, Modal } from 'semantic-ui-react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { AdUser, Contact } from '../../users/model/user.model';
import style from './supervisorField.style';
import { AdUserDropdownField } from '../../users/components/AdUserDropdownField';
import { selectAssessment } from '../store/assessmentDetailSlice';
import { useCtrlEnterToRunCallback } from '../../../core/utils';
import { BrandCode } from '../../../shared/model/brand.model';
import { Supervisor } from '../model/assessment';

interface SupervisorProps {
  defaultValue?: Supervisor;
  editable?: boolean;
  onSave?: (value: Supervisor | null) => void;
  title?: string;
  brand?: BrandCode;
  isValid?: boolean;
}

export const SupervisorField = ({
  defaultValue,
  editable = false,
  onSave = () => null,
  title = t('assessment.actionPlan.responsibleParty') as string,
  brand,
  isValid = true,
}: SupervisorProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [supervisor, setSupervisor] = useState<Supervisor | null>(defaultValue ?? null);
  const assessment = useSelector(selectAssessment);

  const updateValue = () => {
    onSave(supervisor);
    setOpen(false);
  };

  const onCancel = () => {
    setSupervisor(defaultValue ?? null);
    setOpen(false);
  };

  const onAdUserChange = (selectedUser?: AdUser) => {
    if (selectedUser) {
      setSupervisor({
        name: `${selectedUser.firstName} ${selectedUser.familyName}`,
        email: selectedUser.email,
        jobTitle: selectedUser.jobTitle ?? '',
      });
    } else {
      setSupervisor(null);
    }
  };

  const getFullNameWithJobTitle = (contact: Contact | null): string => {
    if (contact == null) {
      return '';
    }
    const jobTitle = contact.jobTitle ? ` - ${contact.jobTitle}` : '';
    return `${contact.name}${jobTitle}`;
  };

  useEffect(() => {
    setSupervisor(defaultValue ?? null);
  }, [defaultValue, open, editable]);

  useCtrlEnterToRunCallback(updateValue, open);

  return (
    <div>
      <div title={title}>
        <div css={style.freeTextTitle(isValid)} title={title}>
          {title}
        </div>
        <div css={editable ? style.editableField : style.field} onClick={() => editable && setOpen(true)}>
          <div css={style.valueContainer}>
            {!isValid && <Icon name='exclamation circle' css={style.validationIcon} />}
            <span css={style.value}>{getFullNameWithJobTitle(supervisor)}</span>
          </div>
          {editable && (
            <div css={style.editIcon}>
              <Icon name='pencil' />
            </div>
          )}
        </div>
      </div>
      <Modal css={style.modal} onClose={() => onCancel()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div css={style.supervisorForm}>
            <div css={style.input}>
              <AdUserDropdownField
                placeholder={t('users.search') || ''}
                onChange={onAdUserChange}
                cornerIcon='search'
                clearable
                defaultValue={
                  supervisor
                    ? {
                        email: supervisor.email,
                        firstName: supervisor.name,
                        familyName: '',
                        jobTitle: supervisor.jobTitle,
                        validBrands: [],
                      }
                    : undefined
                }
                value={supervisor?.email ?? ''}
                assessmentBrand={brand || (assessment?.store?.brand?.code as BrandCode)}
              />
            </div>
            <div css={style.nameContainer}>
              <Input
                value={getFullNameWithJobTitle(supervisor)}
                type='text'
                label={{ icon: 'user' }}
                disabled
                labelPosition='left corner'
                placeholder='Name'
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={onCancel}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
