/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import style, { NoteType } from './auditNotes.style';
import { useCtrlEnterToRunCallback } from '../../../core/utils';

interface AuditNotesProps {
  defaultValue?: string;
  editable?: boolean;
  onEdited?: (values: string) => void;
  disabled?: boolean;
  title: string;
  type?: NoteType;
}

export const AuditNotes = ({
  defaultValue = '',
  editable = false,
  onEdited = () => null,
  disabled = false,
  title,
  type = 'manager',
}: AuditNotesProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [notesState, setNotesState] = React.useState(defaultValue);
  const updateValue = () => {
    if (editable) {
      onEdited(notesState);
    }

    setOpen(false);
  };
  const cancelValue = () => {
    setNotesState(defaultValue);
    setOpen(false);
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (editable) {
      setNotesState(event.target.value);
    }
  };

  useCtrlEnterToRunCallback(updateValue, open);

  return (
    <div css={style.note}>
      <div css={style.header(type, disabled)}>{title}</div>
      <div
        title={title}
        css={editable ? style.editableField(type) : style.field(type, disabled)}
        onClick={() => setOpen(true)}>
        <div style={{ whiteSpace: 'pre-line' }}> {defaultValue} </div>
        {editable ? (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        ) : (
          <div css={style.editIcon}>
            <Icon name='search' />
          </div>
        )}
      </div>
      <Modal css={style.modal} onClose={() => cancelValue()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            <div css={style.inputContainer}>
              <textarea value={notesState} onChange={event => onChangeInput(event)} disabled={!editable} />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={() => cancelValue()}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
