/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { Assessment } from '../model/assessment';
import { AssessmentCard } from './AssessmentCard';
import style from '../pages/assessments.style';

const CARD_WIDTH = 350;
const CARD_HEIGHT = 190;

interface VirtualAssessmentListProps {
  assessmentList: Assessment[];
}

const VirtualAssessmentList = ({ assessmentList }: VirtualAssessmentListProps) => {
  const [listHeight, setListHeight] = useState(CARD_HEIGHT);
  function getListPerRow(assessments: Assessment[], itemsPerRow: number): Assessment[][] {
    if (itemsPerRow === 1) {
      return [assessments];
    }

    return Array.from({ length: Math.ceil(assessments.length / itemsPerRow) }, (_, i) =>
      assessments.slice(i * itemsPerRow, i * itemsPerRow + itemsPerRow)
    );
  }

  return (
    <div css={{ height: `${listHeight}px` }}>
      <AutoSizer>
        {({ height, width }: any) => {
          const itemsPerRow = Math.floor(width / CARD_WIDTH);
          const assessmentsList = getListPerRow(assessmentList, itemsPerRow);

          return (
            <VariableSizeList
              height={height}
              width={width}
              onItemsRendered={() => {
                setListHeight(Math.min(CARD_HEIGHT * assessmentsList.length, CARD_HEIGHT * 3));
              }}
              itemCount={assessmentsList.length}
              itemSize={() => CARD_HEIGHT}
              overscanCount={10}>
              {(rowProps: ListChildComponentProps) => {
                const assessments: Assessment[] = assessmentsList[rowProps.index];
                return (
                  <div style={rowProps.style}>
                    <AssessmentsRow assessments={assessments} itemsPerRow={itemsPerRow} />
                  </div>
                );
              }}
            </VariableSizeList>
          );
        }}
      </AutoSizer>
    </div>
  );
};

interface AssessmentsRowProps {
  assessments: Assessment[];
  itemsPerRow: number;
}

const AssessmentsRow = ({ assessments, itemsPerRow }: AssessmentsRowProps): JSX.Element => {
  return itemsPerRow > 1 ? (
    <div css={style.cardsRowContainer}>
      {assessments.map(a => (
        <AssessmentCard key={a.id} assessment={a} />
      ))}
    </div>
  ) : (
    <AutoSizer>
      {({ height, width }: any) => {
        return (
          <VariableSizeList
            height={height}
            itemCount={assessments.length}
            itemSize={() => CARD_WIDTH}
            width={width}
            overscanCount={10}
            layout='horizontal'>
            {(rowProps: ListChildComponentProps) => {
              const a = assessments[rowProps.index];
              return (
                <div style={rowProps.style}>
                  <AssessmentCard key={a.id} assessment={a} />
                </div>
              );
            }}
          </VariableSizeList>
        );
      }}
    </AutoSizer>
  );
};

export default VirtualAssessmentList;
