/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, Checkbox, Icon, Radio } from 'semantic-ui-react';
import { t } from 'i18next';
import _ from 'lodash';
import { FreeTextField } from './FreeTextField';
import {
  ACTION_PLAN_STATUS,
  AttachmentRef,
  FOLLOW_UP_RESULT,
  FollowUp,
  FollowUpStatus,
  FollowUpSubmitRequest,
  FollowUpUpdateRequest,
  PictureRef,
} from '../model/assessmentDetail.model';
import { SubProcessFooter } from './SubProcessFooter';
import { ActionPlanEditor, Supervisor } from '../model/assessment';
import { AttachmentsListView } from './AttachmentsListView';
import { Contact } from '../../users/model/user.model';
import style from './followUpTab.style';
import { setUnsavedChangesPresence } from '../../../core/unsavedChangesSlice';
import { useAppDispatch } from '../../../core/store';
import { SupervisorField } from './SupervisorField';
import { DueDateQuarterSelectionModal } from './DueDateQuarterSelectionModal';

interface FollowUpTabProps {
  followUp: FollowUp;
  processCode: string;
  subProcessCode: string;
  onSave?: (request: FollowUpUpdateRequest) => void;
  onSubmit?: (updateRequest: FollowUpUpdateRequest, submitRequest: FollowUpSubmitRequest) => void;
  editable?: boolean;
  submittable?: boolean;
  valuesVisibility?: boolean;
  actionPlanEditor?: ActionPlanEditor;
  followUpStatus: FollowUpStatus;
  actionPlanStatus: ACTION_PLAN_STATUS | undefined;
}

export function FollowUpTab({
  followUp,
  processCode,
  subProcessCode,
  onSave = () => null,
  onSubmit = () => null,
  editable = false,
  submittable = false,
  valuesVisibility = false,
  actionPlanEditor,
  followUpStatus,
  actionPlanStatus,
}: FollowUpTabProps): JSX.Element {
  const [result, setResult] = useState<FOLLOW_UP_RESULT>(
    (editable && followUp.result === FOLLOW_UP_RESULT.TODO) ? FOLLOW_UP_RESULT.IMPLEMENTED : followUp.result
  );
  const [description, setDescription] = useState(followUp.description);
  const [dueDate, setDueDate] = useState(followUp.dueDate);
  const [supervisor, setSupervisor] = useState<Supervisor | null>(followUp.supervisor);
  const [pictures, setPictures] = useState(followUp.pictures);
  const [attachments, setAttachments] = useState(followUp.attachments);
  const [submitted] = useState(followUp.submitted);
  const [attachmentsNotMandatory, setAttachmentsNotMandatory] = useState(followUp.attachmentsNotMandatory);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      _.isEqual(followUp.description, description) &&
      _.isEqual(followUp.dueDate, dueDate) &&
      _.isEqual(followUp.supervisor, supervisor) &&
      _.isEqual(followUp.pictures, pictures) &&
      _.isEqual(followUp.attachments, attachments) &&
      _.isEqual(followUp.attachmentsNotMandatory, attachmentsNotMandatory)
    ) {
      dispatch(setUnsavedChangesPresence(false));
    } else {
      dispatch(setUnsavedChangesPresence(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments, description, dispatch, dueDate, pictures, supervisor, attachmentsNotMandatory]);

  const currentFollowUpIsVisible =
    followUpStatus === FollowUpStatus.UNDER_FOLLOWUP &&
    !followUp.completed &&
    !followUp.hiddenAfterPendingToUnderFollowUp;

  const [accordionsVisibility, setAccordionsVisibility] = useState<boolean[]>(
    getInitialAccordionsVisibility(currentFollowUpIsVisible, followUp.history.length)
  );

  const sortedFollowUpHistory = [...followUp.history].reverse();

  const dueDateVisible =
    valuesVisibility && [FOLLOW_UP_RESULT.PARTIALLY_IMPLEMENTED, FOLLOW_UP_RESULT.DELAYED].includes(result);
  const supervisorVisible =
    valuesVisibility && [FOLLOW_UP_RESULT.PARTIALLY_IMPLEMENTED, FOLLOW_UP_RESULT.DELAYED].includes(result);
  const documentationVisible = [FOLLOW_UP_RESULT.PARTIALLY_IMPLEMENTED, FOLLOW_UP_RESULT.IMPLEMENTED].includes(result);

  const dueDateEditable = editable && dueDateVisible;
  const supervisorEditable = editable && supervisorVisible;
  const documentationEditable = editable && documentationVisible;

  const isDescriptionPresent = useMemo(
    () => !editable || (description != null && description !== ''),
    [editable, description]
  );
  const isDueDatePresent = useMemo(
    () => !dueDateEditable || (dueDate != null),
    [dueDateEditable, dueDate]
  );
  const isSupervisorPresent = useMemo(
    () => !supervisorEditable || supervisor != null,
    [supervisorEditable, supervisor]
  );
  const isDocumentationPresent = useMemo(
    () => !documentationEditable || pictures.length + attachments.length > 0,
    [documentationEditable, pictures, attachments]
  );

  const attachmentsAreMandatory = useCallback(
    () =>
      result === FOLLOW_UP_RESULT.PARTIALLY_IMPLEMENTED ||
      (result === FOLLOW_UP_RESULT.IMPLEMENTED && actionPlanStatus !== ACTION_PLAN_STATUS.DONE),
    [result, actionPlanStatus]
  );

  const updateRequest: FollowUpUpdateRequest = useMemo(
    () => ({
      processCode,
      subProcessCode,
      result,
      description,
      dueDate: dueDateEditable && dueDate ? dueDate : null,
      supervisor: supervisorEditable ? supervisor : null,
      pictureIds: documentationEditable ? pictures.map(({ id }) => id) : [],
      attachmentIds: documentationEditable ? attachments.map(({ id }) => id) : [],
      attachmentsNotMandatory: attachmentsAreMandatory() ? attachmentsNotMandatory : false,
    }),
    [
      attachments,
      description,
      documentationEditable,
      dueDate,
      dueDateEditable,
      pictures,
      processCode,
      result,
      subProcessCode,
      supervisor,
      supervisorEditable,
      attachmentsNotMandatory,
      attachmentsAreMandatory,
    ]
  );

  const saveFollowUp = useCallback(async () => {
    onSave(updateRequest);
    setDueDate(updateRequest.dueDate != null ? updateRequest.dueDate : null);
    setSupervisor(updateRequest.supervisor);
    setDescription(updateRequest.description);
    setPictures(followUp.pictures);
    setAttachments(followUp.attachments);
    setAttachmentsNotMandatory(updateRequest.attachmentsNotMandatory);
  }, [followUp.attachments, followUp.pictures, onSave, updateRequest]);

  const handleSubmit = useCallback(async () => {
    const submitRequest: FollowUpSubmitRequest = {
      processCode,
      subProcessCode,
    };
    onSubmit(updateRequest, submitRequest);
  }, [onSubmit, processCode, subProcessCode, updateRequest]);

  const deletePicture = useCallback((picture: PictureRef) => {
    setPictures(prevState => prevState.filter(p => p.id !== picture.id));
  }, []);

  const deleteAttachment = useCallback((attachment: AttachmentRef) => {
    setAttachments(prevState => prevState.filter(p => p.id !== attachment.id));
  }, []);

  const canSave = useMemo(() => editable || (submittable && !submitted), [editable, submittable, submitted]);

  const canSubmit = useMemo(() => submittable && !submitted, [submittable, submitted]);

  const toggleAccordion = useCallback(
    (index: number) => {
      const visibilityList = accordionsVisibility.map((visibilityValue, visibilityIndex) =>
        visibilityIndex === index ? !visibilityValue : visibilityValue
      );
      setAccordionsVisibility(visibilityList);
    },
    [accordionsVisibility]
  );

  return (
    <div css={style.container}>
      <div style={{ overflow: 'auto', flexGrow: 1 }}>
        {currentFollowUpIsVisible && (
          <FollowUpItemAccordion
            title={t('assessment.followUp.currentFollowUp')}
            active={accordionsVisibility[0]}
            onToggle={() => {
              toggleAccordion(0);
            }}>
            <div>
              <div css={style.content}>
                <div css={[style.freeTextContainer, style.disableField(!editable)]}>
                  <ResultBox
                    result={result}
                    onChange={(value: FOLLOW_UP_RESULT) => setResult(value)}
                    editable={editable}
                  />
                </div>
                <div css={[style.freeTextContainer, style.disableField(!editable)]}>
                  <FreeTextField
                    title={t('assessment.followUp.description')}
                    defaultValue={description}
                    editable={editable}
                    onChange={value => setDescription(value)}
                    isValid={isDescriptionPresent}
                  />
                </div>
                <div css={style.disableField(!dueDateEditable)}>
                  <DueDateQuarterSelectionModal
                    title={t('assessment.followUp.dueDate')}
                    value={dueDateVisible && dueDate ? dueDate : null}
                    editable={dueDateEditable}
                    onChange={value => setDueDate(value)}
                    isValid={isDueDatePresent}
                  />
                </div>
                <div css={style.disableField(!supervisorEditable)}>
                  <SupervisorField
                    editable={supervisorEditable}
                    defaultValue={supervisorVisible && supervisor != null ? supervisor : undefined}
                    onSave={value => setSupervisor(value)}
                    isValid={isSupervisorPresent}
                  />
                </div>
                <div style={{ paddingBottom: '10px' }}>
                  <AttachmentsListView
                    pictures={documentationVisible ? pictures : []}
                    onDeletePicture={deletePicture}
                    onAddPictures={pics => setPictures(prevState => prevState.concat(pics))}
                    attachments={documentationVisible ? attachments : []}
                    onDeleteAttachment={deleteAttachment}
                    onAddAttachments={attachs => setAttachments(prevState => prevState.concat(attachs))}
                    editable={documentationEditable}
                    disabled={!documentationEditable}
                    isValid={isDocumentationPresent || !attachmentsAreMandatory() || attachmentsNotMandatory}
                  />
                  {attachmentsAreMandatory() && editable && (
                    <div css={style.attachmentsNotMandatoryContainer}>
                      <div css={style.attachmentsNotMandatoryLabelContainer}>
                        <div css={style.attachmentsNotMandatoryLabel}>
                          {t('assessment.actionPlan.noDocumentsToAttach')}
                        </div>
                        <div css={style.attachmentsNotMandatorySubLabel}>
                          {t('assessment.actionPlan.useThisOnlyForExceptionalCases')}
                        </div>
                      </div>
                      <Checkbox
                        css={style.attachmentsNotMandatoryToggle}
                        toggle
                        checked={attachmentsNotMandatory}
                        onChange={() => setAttachmentsNotMandatory(!attachmentsNotMandatory)}
                      />
                    </div>
                  )}
                </div>
                {followUp.lastUpdateAt != null && followUp.lastUpdateBy != null && (
                  <LastUpdateField lastUpdateAt={followUp.lastUpdateAt} lastUpdateBy={followUp.lastUpdateBy} />
                )}
                <ActionPlanField actionPlanEditor={actionPlanEditor} />
              </div>
            </div>
          </FollowUpItemAccordion>
        )}

        {sortedFollowUpHistory.map((snapshot, index) => (
          <FollowUpItemAccordion
            key={snapshot.approvalDate}
            title={snapshot.approvalDate}
            active={accordionsVisibility[index + 1]}
            onToggle={() => {
              toggleAccordion(index + 1);
            }}>
            <div>
              <div css={style.content}>
                <div css={[style.freeTextContainer, style.disableField(true)]}>
                  <ResultBox result={snapshot.result} />
                </div>
                <div css={[style.freeTextContainer, style.disableField(true)]}>
                  <FreeTextField title={t('assessment.followUp.description')} defaultValue={snapshot.description} />
                </div>
                <div css={style.disableField(true)}>
                  <DueDateQuarterSelectionModal 
                    title={t('assessment.actionPlan.dueDate')}
                    value={snapshot.dueDate}
                  />
                </div>
                <div css={style.disableField(true)}>
                  <SupervisorField editable={false} defaultValue={snapshot.supervisor ?? undefined} />
                </div>
                <div style={{ paddingBottom: '10px' }}>
                  <AttachmentsListView
                    pictures={pictures}
                    attachments={attachments}
                    editable={false}
                    disabled
                    onDeletePicture={_pic => {
                      // intentionally unimplemented
                    }}
                    onAddPictures={_pics => {
                      // intentionally unimplemented
                    }}
                    onDeleteAttachment={_attachment => {
                      // intentionally unimplemented
                    }}
                    onAddAttachments={_attachs => {
                      // intentionally unimplemented
                    }}
                  />
                </div>
                <LastUpdateField lastUpdateBy={snapshot.lastUpdateBy} />
              </div>
            </div>
          </FollowUpItemAccordion>
        ))}
      </div>
      {editable && (
        <SubProcessFooter onSave={saveFollowUp} canSave={canSave} onSubmit={handleSubmit} canSubmit={canSubmit} />
      )}
    </div>
  );
}

interface StatusBoxProps {
  result: FOLLOW_UP_RESULT;
  onChange?: (value: FOLLOW_UP_RESULT) => void;
  editable?: boolean;
}

function ResultBox({ result, onChange = () => null, editable = false }: StatusBoxProps): JSX.Element {
  const [value, setValue] = useState(result);

  useEffect(() => {
    setValue(result);
  }, [result]);

  return (
    <div css={style.statusContainer}>
      <div css={style.statusTitle}>{t('assessment.followUp.result')}</div>
      <div css={style.statusRadioBox}>
        <Radio
          checked={value === FOLLOW_UP_RESULT.IMPLEMENTED}
          onChange={() => onChange(FOLLOW_UP_RESULT.IMPLEMENTED)}
          css={style.statusRadioElement}
          disabled={!editable}
          label={t('assessment.followUp.implemented')}
        />
        <Radio
          checked={value === FOLLOW_UP_RESULT.PARTIALLY_IMPLEMENTED}
          onChange={() => onChange(FOLLOW_UP_RESULT.PARTIALLY_IMPLEMENTED)}
          css={style.statusRadioElement}
          disabled={!editable}
          label={t('assessment.followUp.partiallyImplemented')}
        />
        <Radio
          checked={value === FOLLOW_UP_RESULT.DELAYED}
          onChange={() => onChange(FOLLOW_UP_RESULT.DELAYED)}
          css={style.statusRadioElement}
          disabled={!editable}
          label={t('assessment.followUp.delayed')}
        />
        <Radio
          checked={value === FOLLOW_UP_RESULT.CLOSED}
          onChange={() => onChange(FOLLOW_UP_RESULT.CLOSED)}
          css={style.statusRadioElement}
          disabled={!editable}
          label={t('assessment.followUp.closed')}
        />
      </div>
    </div>
  );
}

interface FollowUpItemAccordionProps {
  title: string;
  active: boolean;
  onToggle: () => void;
  children: JSX.Element;
}
const FollowUpItemAccordion = ({ title, active, onToggle, children }: FollowUpItemAccordionProps): JSX.Element => {
  return (
    <Accordion css={style.accordionItem}>
      <Accordion.Title active={active} css={style.accordionTitle} onClick={() => onToggle()}>
        <Icon name='dropdown' />
        <div>{title}</div>
      </Accordion.Title>
      <Accordion.Content active={active} css={style.accordionContent}>
        <Accordion.Content active={active}>{children}</Accordion.Content>
      </Accordion.Content>
    </Accordion>
  );
};

const LastUpdateField = ({
  lastUpdateBy,
  lastUpdateAt,
}: {
  lastUpdateBy: Contact;
  lastUpdateAt?: string;
}): JSX.Element => {
  return (
    <div css={style.updateField}>
      <span css={style.updateTitle}>{t('assessment.followUp.updatedBy')}: </span>
      <span css={style.updateText}>{lastUpdateBy.name}</span>
      {lastUpdateAt && (
        <>
          <span css={style.updateDateLabel}>{t('assessment.followUp.at')} </span>
          <span css={style.updateText}>{lastUpdateAt}</span>
        </>
      )}
    </div>
  );
};

const ActionPlanField = ({ actionPlanEditor }: { actionPlanEditor?: ActionPlanEditor }): JSX.Element => {
  return (
    <div css={style.updateField}>
      <span css={style.updateTitle}>{t('assessment.followUp.editor')}: </span>
      <span css={style.updateText}>{actionPlanEditor?.name || t('assessment.followUp.unassigned')}</span>
    </div>
  );
};

const getInitialAccordionsVisibility = (currentFollowUpIsVisible: boolean, historyLength: number) => {
  const historyVisibilities = new Array(historyLength).fill(false).map((element, index) => {
    return !currentFollowUpIsVisible && index === 0;
  });

  return [currentFollowUpIsVisible, ...historyVisibilities];
};
