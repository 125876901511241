import {
  ActionPlanEditor,
  ActionPlanSubmitAuthor,
  Assessment,
  Auditor,
  AuditScoreRating,
  Quarter,
  Supervisor,
  toAssessment,
  toAuditor,
  toSuperVisor,
} from './assessment';
import { Contact, toContact } from '../../users/model/user.model';
import { getDateTimeString, getUTCDateString } from '../../../core/utils';

export interface AssessmentDetail extends Assessment {
  storeManagerSince: string; // date
  storeManagerNotAvailable: boolean;
  overview: string;
  notes: Comment[];
  retailProcedure: boolean;
  entityAudit: boolean;
  lastAudit: AuditResult;
  retailAreaManager: Contact;
  audit: Audit;
  auditScore?: AuditScore;
  followUpStatus: FollowUpStatus;
  regionalManager?: Contact;
}

export interface TopicForDiscussionUpsertResponse {
  assessment: AssessmentDetail;
  topic: TopicForDiscussion;
}

export interface TopicForDiscussionUpsertRequest {
  title: string;
}

export enum FollowUpStatus {
  EMPTY = 'EMPTY',
  UNDER_FOLLOWUP = 'UNDER_FOLLOWUP',
  DONE = 'DONE',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export interface AuditScore {
  overallRatingPercentage: number;
  rating: AuditScoreRating;
  processScores: ProcessScore[];
}

export interface ProcessScore {
  code: string;
  description: string;
  scorePercentage: number;
  rating: ProcessScoreRating;
}

export enum ProcessScoreRating {
  P = '0',
  A = '2',
  B = '4',
  C = '6',
  D = '10',
}

export const auditInternalControlDescription = new Map([
  [AuditScoreRating.P, 'Good'],
  [AuditScoreRating.A, 'Good'],
  [AuditScoreRating.B, 'Satisfactory'],
  [AuditScoreRating.C, 'Marginally deficient'],
  [AuditScoreRating.D, 'Deficient'],
]);

export const processInternalControlDescription = new Map([
  [ProcessScoreRating.P, 'Main controls in place and effective'],
  [ProcessScoreRating.A, 'Main controls in place and effective'],
  [ProcessScoreRating.B, 'Sufficient and effective controls in place'],
  [ProcessScoreRating.C, 'Limited and/or ineffective controls in place'],
  [ProcessScoreRating.D, 'Insufficient and/or ineffective controls in place'],
]);

export const toAuditScore = (o: Record<string, unknown>): AuditScore => ({
  overallRatingPercentage: o.overallRatingPercentage as number,
  rating: o.rating as AuditScoreRating,
  processScores: (o.processScores as Record<string, unknown>[]).map(toProcessScore),
});

export const toProcessScore = (o: Record<string, unknown>): ProcessScore => ({
  code: o.code as string,
  description: o.description as string,
  scorePercentage: o.scorePercentage as number,
  rating: o.rating as ProcessScoreRating,
});

export interface Audit {
  templateVersion: number;
  processes: Process[];
  topicsForDiscussion: TopicForDiscussion[];
}

export interface TopicForDiscussion {
  id: string;
  title: string;
  comments: TopicComment[];
}

export interface Process {
  code: string;
  description: string;
  subProcesses: SubProcess[];
}

export interface SubProcess {
  code: string;
  description: string;
  result: SubProcessResult;
  actionPlan: ActionPlan | null;
  auditingResults: AuditingResult[];
  info: string;
  processCode: string;
  processDescription: string;
  availableKeyBusinessRisks: string[];
  followUp: FollowUp | null;
}

export interface ActionPlan {
  auditorNote?: string;
  description: string;
  dueDate: Quarter | null;
  supervisor: Supervisor | null;
  status: ACTION_PLAN_STATUS;
  editor: ActionPlanEditor | null;
  lastUpdateAuthor: ActionPlanSubmitAuthor | null;
  submitted: boolean;
  lastUpdate: string | null;
  brandNote?: string;
  pictures: PictureRef[];
  attachments: AttachmentRef[];
  attachmentsNotMandatory: boolean;
  attachmentsToBeChecked: boolean;
}

interface FollowUpSnapshot {
  result: FOLLOW_UP_RESULT;
  description: string;
  dueDate: Quarter | null;
  supervisor: Supervisor;
  supervisorRole: string;
  pictures: PictureRef[];
  attachments: AttachmentRef[];
  lastUpdateBy: Contact;
  approvalDate: string;
}

export interface FollowUp {
  result: FOLLOW_UP_RESULT;
  description: string;
  dueDate: Quarter | null;
  supervisor: Supervisor | null;
  history: FollowUpSnapshot[];
  pictures: PictureRef[];
  attachments: AttachmentRef[];
  lastUpdateAt: string | null;
  lastUpdateBy: Contact | null;
  submitted: boolean;
  completed: boolean;
  hiddenAfterPendingToUnderFollowUp: boolean;
  attachmentsNotMandatory: boolean;
  attachmentsToBeChecked: boolean;
}

export enum ACTION_PLAN_STATUS {
  'DONE' = 'DONE',
  'SCHEDULED' = 'SCHEDULED',
  'NOT_PROGRAMMABLE' = 'NOT PROGRAMMABLE',
}

export enum FOLLOW_UP_RESULT {
  'IMPLEMENTED' = 'IMPLEMENTED',
  'PARTIALLY_IMPLEMENTED' = 'PARTIALLY_IMPLEMENTED',
  'DELAYED' = 'DELAYED',
  'CLOSED' = 'CLOSED',
  'TODO' = 'TO_DO',
}

export const emptyActionPlan = (): ActionPlan => ({
  auditorNote: '',
  description: '',
  dueDate: null,
  supervisor: null,
  status: ACTION_PLAN_STATUS.SCHEDULED,
  editor: null,
  lastUpdateAuthor: null,
  submitted: false,
  lastUpdate: null,
  brandNote: '',
  pictures: [],
  attachments: [],
  attachmentsNotMandatory: false,
  attachmentsToBeChecked: false,
});

export const emptyFollowUp = (): FollowUp => ({
  result: FOLLOW_UP_RESULT.IMPLEMENTED,
  description: '',
  dueDate: null,
  supervisor: null,
  pictures: [],
  attachments: [],
  history: [],
  lastUpdateAt: null,
  lastUpdateBy: null,
  submitted: false,
  completed: false,
  hiddenAfterPendingToUnderFollowUp: false,
  attachmentsNotMandatory: false,
  attachmentsToBeChecked: false,
});

export const toActionPlan = (o: Record<string, unknown>): ActionPlan => {
  return {
    auditorNote: o.auditorNote as string,
    description: o.description as string,
    dueDate: o.dueDate != null ? (o.dueDate as Quarter) : null,
    supervisor: o.supervisor ? toSuperVisor(o.supervisor as Record<string, string>) : null,
    status: o.status as ACTION_PLAN_STATUS,
    editor: o.editor as ActionPlanEditor,
    lastUpdateAuthor: o.lastUpdateAuthor as ActionPlanSubmitAuthor,
    submitted: o.submitted as boolean,
    lastUpdate: getDateTimeString(o.lastUpdate as string),
    brandNote: o.brandNote as string,
    pictures: o.pictures != null ? (o.pictures as Record<string, string>[]).map(toPictureRef) : [],
    attachments: o.attachments != null ? (o.attachments as Record<string, string>[]).map(toAttachmentRef) : [],
    attachmentsNotMandatory: o.attachmentsNotMandatory as boolean,
    attachmentsToBeChecked: o.attachmentsToBeChecked as boolean,
  };
};

const toPictureRef = (attachment: Record<string, string>): PictureRef => ({ id: attachment.id, data: attachment.data });

const toAttachmentRef = (attachment: Record<string, string>): AttachmentRef => ({
  id: attachment.id,
  filename: attachment.filename,
});

const toFollowUpSnapshot = (snapshot: Record<string, unknown>): FollowUpSnapshot => {
  return {
    result: snapshot.result as FOLLOW_UP_RESULT,
    description: snapshot.description as string,
    dueDate: snapshot.dueDate != null ? (snapshot.dueDate as Quarter) : null,
    supervisor: snapshot.supervisor as Supervisor,
    supervisorRole: snapshot.supervisorRole as string,
    pictures: (snapshot.pictures as Record<string, string>[]).map(toPictureRef),
    attachments: (snapshot.attachments as Record<string, string>[]).map(toAttachmentRef),
    lastUpdateBy: toContact(snapshot.lastUpdateBy as Record<string, unknown>),
    approvalDate: getDateTimeString(snapshot.approvalDate as string),
  };
};

export const toFollowUp = (o: Record<string, unknown>): FollowUp => {
  return {
    result: o.result as FOLLOW_UP_RESULT,
    description: o.description as string,
    dueDate: o.dueDate != null ? (o.dueDate as Quarter) : null,
    supervisor: o.supervisor as Supervisor,
    pictures: o.pictures != null ? (o.pictures as Record<string, string>[]).map(toPictureRef) : [],
    attachments: o.attachments != null ? (o.attachments as Record<string, string>[]).map(toAttachmentRef) : [],
    history: (o.history as Record<string, unknown>[]).map(toFollowUpSnapshot),
    lastUpdateAt: getDateTimeString(o.lastUpdateAt as string),
    lastUpdateBy: o.lastUpdateBy as Contact,
    submitted: o.submitted as boolean,
    completed: o.completed as boolean,
    hiddenAfterPendingToUnderFollowUp: o.hiddenAfterPendingToUnderFollowUp as boolean,
    attachmentsNotMandatory: o.attachmentsNotMandatory as boolean,
    attachmentsToBeChecked: o.attachmentsToBeChecked as boolean,
  };
};

export interface AuditingResult extends SubProcessResult {
  id: string;
  auditor: Auditor;
  processCode: string;
  subProcessCode: string;
}

export const emptyAuditingResult = (
  auditor: Auditor,
  processCode: string,
  subProcessCode: string,
  availableKeyBusinessRisks: string[]
): AuditingResult => ({
  id: '',
  auditor,
  processCode,
  subProcessCode,
  ...emptySubProcessResult(availableKeyBusinessRisks),
});

export enum ResultStatus {
  'TO_DO' = 'TO_DO',
  'PASSED' = 'PASSED',
  'FAILED' = 'FAILED',
}

export enum Priority {
  'PASS' = 'PASS',
  'LOW' = 'LOW',
  'MEDIUM' = 'MEDIUM',
  'SIGNIFICANT' = 'SIGNIFICANT',
  'HIGH' = 'HIGH',
}

export const getPriorityIconClassName = (priority: Priority): string => {
  switch (priority) {
    case Priority.MEDIUM:
      return 'fas fa-minus';
    case Priority.SIGNIFICANT:
      return 'fas fa-angle-up';
    case Priority.HIGH:
      return 'fas fa-angle-double-up';
    case Priority.LOW:
      return 'fas fa-angle-down';
    default:
      return '';
  }
};

export const getPriorities = (): Priority[] => Object.values(Priority).filter(p => p !== Priority.PASS);

export enum SubProcessError {
  'VALIDATION' = 'VALIDATION',
  'MERGE' = 'MERGE',
}

export interface SubProcessResult {
  version: string;
  status: ResultStatus;
  observation: string;
  finding: string;
  recommendation: string;
  priority: Priority;
  priorityRationale: string;
  bestCasePractice: boolean;
  bestCasePracticeNote: string;
  score: number;
  managerNote: string;
  regionalManagerNote: string;
  pictures: PictureRef[];
  attachments: AttachmentRef[];
  keyBusinessRisks: string[];
}
export interface PictureRef {
  id: string;
  data: string;
}

export interface AttachmentRef {
  id: string;
  filename: string;
}

const emptySubProcessResultMerging = (
  auditingResult: AuditingResult[],
  availableKeyBusinessRisks: string[]
): SubProcessResult => {
  return {
    version: '',
    status: ResultStatus.TO_DO,
    observation: '',
    finding: '',
    recommendation: '',
    priority: Priority.PASS,
    priorityRationale: '',
    bestCasePractice: false,
    bestCasePracticeNote: '',
    score: 0,
    managerNote: '',
    regionalManagerNote: '',
    pictures: auditingResult.flatMap(ar => ar.pictures),
    attachments: auditingResult.flatMap(att => att.attachments),
    keyBusinessRisks: availableKeyBusinessRisks.length === 1 ? [...availableKeyBusinessRisks] : [],
  };
};

const emptySubProcessResult = (availableKeyBusinessRisks: string[]): SubProcessResult =>
  emptySubProcessResultMerging([], availableKeyBusinessRisks);

export const toAssessmentDetail = (o: Record<string, unknown>): AssessmentDetail => ({
  ...toAssessment(o),
  storeManagerSince: o.storeManagerSince != null ? getUTCDateString(o.storeManagerSince as string) : '',
  storeManagerNotAvailable: o.storeManagerNotAvailable as boolean,
  overview: o.overview as string,
  notes: (o.notes as Record<string, unknown>[]).map(n => toComment(n)),
  retailProcedure: o.retailProcedure as boolean,
  entityAudit: o.entityAudit as boolean,
  lastAudit: o.lastAudit != null ? toAuditResult(o.lastAudit as Record<string, unknown>) : emptyAuditResult(),
  retailAreaManager: toContact(o.retailAreaManager as Record<string, unknown>),
  audit: toAudit(o.audit as Record<string, unknown>),
  auditScore: o.auditScore != null ? toAuditScore(o.auditScore as Record<string, unknown>) : undefined,
  followUpStatus: o.followUpStatus == null ? FollowUpStatus.EMPTY : (o.followUpStatus as FollowUpStatus),
  regionalManager: o.regionalManager as Contact,
});

export const toTopicForDiscussionUpsertResponse = (o: Record<string, unknown>): TopicForDiscussionUpsertResponse => ({
  assessment: toAssessmentDetail(o.assessment as Record<string, unknown>),
  topic: toTopicForDiscussion(o.topic as Record<string, unknown>),
});

interface AuditResult {
  period: string;
  rating: string;
}

export interface Comment {
  author: Contact;
  created: string;
  lastUpdate: string | null;
  content: string;
  fromAuditor: boolean;
}

export interface TopicComment {
  id: string;
  author: Contact;
  created: string;
  lastUpdate: string | null;
  message: string;
  fromAuditor: boolean;
}

const toComment = (o: Record<string, unknown>): Comment => {
  return {
    author: toContact(o.author as Record<string, unknown>),
    created: o.created as string,
    lastUpdate: o.lastUpdate != null ? (o.lastUpdate as string) : null,
    content: o.content as string,
    fromAuditor: o.fromAuditor as boolean,
  };
};

const toTopicComment = (o: Record<string, unknown>): TopicComment => {
  return {
    id: o.id as string,
    author: o.author as Contact,
    created: o.created as string,
    lastUpdate: o.lastUpdate != null ? (o.lastUpdate as string) : null,
    message: o.message as string,
    fromAuditor: o.fromAuditor as boolean,
  };
};

const toSubProcess = (o: Record<string, unknown>, processCode: string, processDescription: string): SubProcess => {
  const auditingResults = (o.auditingResults as Record<string, unknown>[]).map(p => toAuditingResult(p));
  return {
    code: o.code as string,
    description: o.description as string,
    result:
      o.result != null
        ? toSubProcessResult(o.result as Record<string, unknown>)
        : emptySubProcessResultMerging(auditingResults, (o.availableKeyBusinessRisks as string[]) || []),
    actionPlan: o.actionPlan != null ? toActionPlan(o.actionPlan as Record<string, unknown>) : null,
    auditingResults,
    info: o.info != null ? (o.info as string) : '',
    processCode,
    processDescription,
    availableKeyBusinessRisks: (o.availableKeyBusinessRisks as string[]) || [],
    followUp: o.followUp != null ? toFollowUp(o.followUp as Record<string, unknown>) : null,
  };
};

const toProcess = (o: Record<string, unknown>): Process => ({
  code: o.code as string,
  description: o.description as string,
  subProcesses: (o.subProcesses as Record<string, unknown>[]).map(sp =>
    toSubProcess(sp, o.code as string, o.description as string)
  ),
});

const toTopicForDiscussion = (o: Record<string, unknown>): TopicForDiscussion => {
  return {
    id: o.id as string,
    title: o.title as string,
    comments: (o.comments as Record<string, unknown>[]).map(c => toTopicComment(c)),
  };
};

export const toAudit = (o: Record<string, unknown>): Audit => {
  return {
    templateVersion: o.templateVersion as number,
    processes: (o.processes as Record<string, unknown>[]).map(p => toProcess(p)),
    topicsForDiscussion: (o.topicsForDiscussion as Record<string, unknown>[]).map(t => toTopicForDiscussion(t)),
  };
};

const toSubProcessResult = (o: Record<string, unknown>): SubProcessResult => ({
  version: o.version as string,
  status: o.status as ResultStatus,
  observation: o.observation as string,
  finding: o.finding as string,
  recommendation: o.recommendation as string,
  priority: o.priority as Priority,
  priorityRationale: o.priorityRationale as string,
  bestCasePractice: o.bestCasePractice as boolean,
  bestCasePracticeNote: o.bestCasePracticeNote as string,
  score: o.score as number,
  managerNote: o.managerNote as string,
  regionalManagerNote: o.regionalManagerNote as string,
  pictures: o.pictures != null ? (o.pictures as Record<string, string>[]).map(toPictureRef) : [],
  attachments: o.attachments != null ? (o.attachments as Record<string, string>[]).map(toAttachmentRef) : [],
  keyBusinessRisks: (o.keyBusinessRisks as string[]) || [],
});

export const toAuditingResult = (o: Record<string, unknown>): AuditingResult => ({
  id: `${o.processCode}-${o.subProcessCode}-${(o.auditor as Record<string, unknown>).email}` as string,
  auditor: toAuditor(o.auditor as Record<string, unknown>),
  version: o.version as string,
  status: o.status as ResultStatus,
  observation: o.observation as string,
  finding: o.finding as string,
  recommendation: o.recommendation as string,
  priority: o.priority as Priority,
  priorityRationale: o.priorityRationale as string,
  bestCasePractice: o.bestCasePractice as boolean,
  bestCasePracticeNote: o.bestCasePracticeNote as string,
  score: o.score as number,
  managerNote: o.managerNote as string,
  regionalManagerNote: o.regionalManagerNote as string,
  processCode: o.processCode as string,
  subProcessCode: o.subProcessCode as string,
  pictures: o.pictures != null ? (o.pictures as Record<string, string>[]).map(toPictureRef) : [],
  attachments: o.attachments != null ? (o.attachments as Record<string, string>[]).map(toAttachmentRef) : [],
  keyBusinessRisks: (o.keyBusinessRisks as string[]) || [],
});

const toAuditResult = (o: Record<string, unknown>): AuditResult => ({
  period: `${o.year}-${o.month}`,
  // todo
  rating: o.rating as string,
});

const emptyAuditResult = (): AuditResult => ({ period: '', rating: '' });

export enum MERGING_PROCESS_STATUS {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum SENT_TO_THE_STORES_PROCESS_STATUS {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export const getMergingProcessError = (process: Process): MERGING_PROCESS_STATUS => {
  const success = process.subProcesses.map(sp => getMergingSubProcessError(sp)).filter(s => s == null);
  if (process.subProcesses.length === success.length) {
    return MERGING_PROCESS_STATUS.SUCCESS;
  }
  return MERGING_PROCESS_STATUS.ERROR;
};

export const getSentToTheStoreProcessError = (process: Process): SENT_TO_THE_STORES_PROCESS_STATUS => {
  const success = process.subProcesses.map(sp => getActionPlanSubProcessError(sp)).filter(s => s == null);
  if (process.subProcesses.length === success.length) {
    return SENT_TO_THE_STORES_PROCESS_STATUS.SUCCESS;
  }
  return SENT_TO_THE_STORES_PROCESS_STATUS.ERROR;
};

export const getMergingSubProcessError = (subProcess: SubProcess): SubProcessError | undefined => {
  if (subProcess.auditingResults.length > 0) return SubProcessError.MERGE;
  return getSubProcessResultError(subProcess.result);
};

export const getActionPlanSubProcessError = (subProcess: SubProcess): SubProcessError | undefined => {
  return getActionPlanError(subProcess.actionPlan || emptyActionPlan(), subProcess.result);
};

// eslint-disable-next-line consistent-return
export const getSubProcessResultError = (result: SubProcessResult): SubProcessError | undefined => {
  const fieldIsNotValid = (field: 'observation' | 'finding' | 'recommendation' | 'priorityRationale') => {
    return result[field] === '' || result[field] == null;
  };
  const priorityIsNotValid = () => result.priority === Priority.PASS;
  const statusIsNotValid = () => result.status === ResultStatus.TO_DO;

  const keyBusinessRisksNotValid = () => result.keyBusinessRisks == null || result.keyBusinessRisks.length === 0;

  const checkValidation = () => {
    return (
      fieldIsNotValid('finding') ||
      fieldIsNotValid('recommendation') ||
      fieldIsNotValid('priorityRationale') ||
      priorityIsNotValid() ||
      statusIsNotValid() ||
      keyBusinessRisksNotValid()
    );
  };
  if (result.status === ResultStatus.PASSED) return undefined;
  if (checkValidation()) {
    return SubProcessError.VALIDATION;
  }
};

export const getActionPlanError = (actionPlan: ActionPlan, result: SubProcessResult): SubProcessError | undefined => {
  const fieldIsNotValid = (field: 'description' | 'dueDate' | 'supervisor') => {
    return actionPlan[field] === '' || actionPlan[field] == null;
  };

  const isNotValid = () => {
    return (
      fieldIsNotValid('description') ||
      (fieldIsNotValid('dueDate') && ACTION_PLAN_STATUS.SCHEDULED === actionPlan.status) ||
      (fieldIsNotValid('supervisor') && ACTION_PLAN_STATUS.NOT_PROGRAMMABLE !== actionPlan.status) ||
      (actionPlan.attachments.length === 0 &&
        actionPlan.pictures.length === 0 &&
        ACTION_PLAN_STATUS.DONE === actionPlan.status &&
        result.status === ResultStatus.FAILED &&
        [Priority.HIGH, Priority.SIGNIFICANT].includes(result.priority) &&
        !actionPlan.attachmentsNotMandatory)
    );
  };
  if (result.status === ResultStatus.PASSED) return undefined;
  if (isNotValid()) {
    return SubProcessError.VALIDATION;
  }
  return undefined;
};

export const hasManagerNote = (subProcess: SubProcess): boolean => {
  return subProcess.result.managerNote != null && subProcess.result.managerNote !== '';
};

export const hasRegionalManagerNote = (subProcess: SubProcess): boolean => {
  return subProcess.result.regionalManagerNote != null && subProcess.result.regionalManagerNote !== '';
};

export const hasAuditorNote = (subProcess: SubProcess): boolean => {
  return (
    subProcess.actionPlan != null &&
    subProcess.actionPlan.auditorNote != null &&
    subProcess.actionPlan.auditorNote !== ''
  );
};

export const hasActionPlanCoordinatorNote = (subProcess: SubProcess): boolean => {
  return (
    subProcess.actionPlan != null && subProcess.actionPlan.brandNote != null && subProcess.actionPlan.brandNote !== ''
  );
};

export const hasManagerNoteByProcess = (process: Process): boolean => {
  const compiled = process.subProcesses.map(sp => hasManagerNote(sp)).filter(s => s);
  return compiled.length > 0;
};

export const hasRegionalManagerNoteByProcess = (process: Process): boolean => {
  const compiled = process.subProcesses.map(sp => hasRegionalManagerNote(sp)).filter(s => s);
  return compiled.length > 0;
};

export const hasAuditorNoteByProcess = (process: Process): boolean => {
  const compiled = process.subProcesses.map(sp => hasAuditorNote(sp)).filter(s => s);
  return compiled.length > 0;
};

export const hasActionPlanCoordinatorNoteByProcess = (process: Process): boolean => {
  const compiled = process.subProcesses.map(sp => hasActionPlanCoordinatorNote(sp)).filter(s => s);
  return compiled.length > 0;
};

export const isProcessPassed = (p: Process): boolean => {
  const passed = p.subProcesses.filter(sp => sp.result.status === ResultStatus.PASSED);
  return passed.length === p.subProcesses.length;
};

export const isProcessAttachmentsToBeChecked = (p: Process): boolean => {
  return p.subProcesses.some(
    sp =>
      (sp.actionPlan != null && sp.actionPlan.attachmentsToBeChecked) ||
      (sp.followUp != null && sp.followUp.result != null && sp.followUp.attachmentsToBeChecked)
  );
};

export const getMergingSubProcessesWithError = (processes: Process[]): SubProcess[] => {
  return processes.flatMap(p => p.subProcesses).filter(sp => getMergingSubProcessError(sp) != null);
};

export const getActionPlanSubProcessesWithError = (processes: Process[]): SubProcess[] => {
  return processes.flatMap(p => p.subProcesses).filter(sp => getActionPlanSubProcessError(sp) != null);
};

export interface AssessmentUpdateAuditorsRequest {
  auditorEmails: string[];
}

export interface AssessmentUpdateYearAndMonthRequest {
  year: number;
  month: number;
}

export interface AssessmentUpdateDistributionListRequest {
  distributionList: string[];
}

export interface AssessmentUpdateReadOnlyDistributionListRequest {
  readOnlyDistributionList: string[];
}
export interface AssessmentUpdateBrandCoordinatorListRequest {
  brandCoordinatorList: UpdateBrandCoordinatorRequest[];
}
export interface CreateCommentRequest {
  content: string;
}
export interface CreateTopicCommentRequest {
  message: string;
}
export interface UpdateCommentRequest {
  created: string;
  content: string;
}
export interface AssessmentUpdateFreeTextFieldRequest {
  content: string;
}

export interface AssessmentUpdateStatusRequest {
  status: string;
}

export interface UpdateStoreManagerSinceRequest {
  storeManagerSince: string;
}

export interface UpdateStoreManagerRequest {
  name: string;
  email: string;
  storeManagerNotAvailable: boolean;
}

export interface UpdateRetailProcedureRequest {
  value: boolean;
}

export interface UpdateEntityAuditRequest {
  value: boolean;
}

export interface UpdateContactRequest {
  name: string;
  email: string;
}

export interface UpdateBrandCoordinatorRequest {
  email: string;
  role: string;
}

export interface UpdateNumberFieldRequest {
  number: number;
}

export interface ResultUpdateRequest {
  processCode: string;
  subProcessCode: string;
  status: ResultStatus;
  observation: string;
  finding: string;
  recommendation: string;
  priority: Priority | '';
  priorityRationale: string;
  bestCasePractice: boolean;
  bestCasePracticeNote: string;
  pictures: PictureRef[];
  attachments: AttachmentRef[];
  keyBusinessRisks: string[];
}

export interface ActionPlanUpdateRequest {
  processCode: string;
  subProcessCode: string;
  description: string;
  dueDate: Quarter | null;
  supervisor: Supervisor | null;
  status: ACTION_PLAN_STATUS;
  pictures: PictureRef[];
  attachments: AttachmentRef[];
  attachmentsNotMandatory: boolean;
}

export interface FollowUpUpdateRequest {
  processCode: string;
  subProcessCode: string;
  result: FOLLOW_UP_RESULT;
  description: string;
  dueDate: Quarter | null;
  supervisor: Supervisor | null;
  pictureIds: string[];
  attachmentIds: string[];
  attachmentsNotMandatory: boolean;
}

export interface AuditorUpdateRequest extends ResultUpdateRequest {
  auditorEmail: string;
  assessmentId: string;
}

export const mergeAuditingResultAndResultUpdateRequest = (
  auditingResult: AuditingResult,
  o: ResultUpdateRequest
): AuditingResult => ({
  id: `${o.processCode}-${o.subProcessCode}-${auditingResult.auditor.email}` as string,
  auditor: toAuditor(auditingResult.auditor as unknown as Record<string, unknown>),
  version: auditingResult.version as string,
  status: o.status as ResultStatus,
  observation: o.observation as string,
  finding: o.finding as string,
  recommendation: o.recommendation as string,
  priority: o.priority as Priority,
  priorityRationale: o.priorityRationale as string,
  bestCasePractice: o.bestCasePractice as boolean,
  bestCasePracticeNote: o.bestCasePracticeNote as string,
  score: auditingResult.score as number,
  managerNote: auditingResult.managerNote as string,
  regionalManagerNote: auditingResult.regionalManagerNote as string,
  processCode: o.processCode as string,
  subProcessCode: o.subProcessCode as string,
  pictures: o.pictures as PictureRef[],
  attachments: o.attachments as AttachmentRef[],
  keyBusinessRisks: o.keyBusinessRisks as string[],
});
export interface SubProcessNoteRequest {
  processCode: string;
  subProcessCode: string;
  note: string;
}

export interface TopicForDiscussionRequest {
  id: string;
  title: string;
}

export interface PictureBase64CreationRequest {
  data: string;
  contentType: string;
}

export interface AttachmentBase64CreationRequest {
  data: string;
  contentType: string;
  filename: string;
}

export interface ActionPlanEditorUpdateRequest {
  processCode: string;
  subProcessCode: string;
  name: string;
  email: string;
}

export interface ActionPlanSubmitRequest {
  processCode: string;
  subProcessCode: string;
  submitted: boolean;
}

export interface ActionPlanAttachmentsCheckedRequest {
  processCode: string;
  subProcessCode: string;
  attachmentsChecked: boolean;
}

export interface FollowUpAttachmentsCheckedRequest {
  processCode: string;
  subProcessCode: string;
  attachmentsChecked: boolean;
}

export interface FollowUpSubmitRequest {
  processCode: string;
  subProcessCode: string;
}
