/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Button, Icon, Input, Modal, SemanticICONS, SemanticShorthandItem } from 'semantic-ui-react';
import { LabelProps } from 'semantic-ui-react/dist/commonjs/elements/Label';
import style from './textInputField.style';
import { useCtrlEnterToRunCallback } from '../../../core/utils';

interface InputFieldProps {
  defaultValue?: string;
  editable?: boolean;
  onEdited?: (value: string) => void;
  icon?: SemanticICONS;
  className?: string;
  label?: SemanticShorthandItem<LabelProps>;
  title: string;
  placeholder: string;
  mutable?: boolean;
}

export const TextInputField = ({
  defaultValue = '',
  editable = false,
  onEdited = () => null,
  icon,
  className = '',
  label,
  title,
  placeholder,
  mutable = false,
}: InputFieldProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue);

  const updateValue = () => {
    onEdited(value);
    setOpen(false);
  };
  const cancelValue = () => {
    setValue(defaultValue);
    setOpen(false);
  };

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useCtrlEnterToRunCallback(updateValue, open);

  useEffect(() => {
    if (mutable) setValue(defaultValue);
  }, [defaultValue, mutable]);

  return (
    <>
      <div title={title} css={editable ? style.editableField : style.field} onClick={() => editable && setOpen(true)}>
        <Icon name={icon} className={className} />
        <div css={style.valueContainer}>
          <span css={style.value}> {defaultValue || ''} </span>
          <span css={style.subTitle}>{title}</span>
        </div>
        {editable && (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        )}
      </div>
      <Modal css={style.modal} onClose={() => cancelValue()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            <div css={style.inputContainer}>
              <Input
                value={value}
                type='text'
                label={label}
                onChange={event => onChangeValue(event)}
                labelPosition='left corner'
                placeholder={placeholder}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={() => cancelValue()}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
