import { Cell, Worksheet } from 'exceljs';
import ReactPDF, { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { ReactElement } from 'react';
import XLSXUtils from '../../../core/services/XLSXUtils';
import {
  ACTION_PLAN_STATUS,
  AssessmentDetail,
  Priority,
  SubProcess,
  TopicForDiscussion,
} from '../model/assessmentDetail.model';
import { getDateString } from '../../../core/utils';
import { Status, STATUS } from '../model/assessmentStatus';

export const exportFindingsAndTopicsXls = (
  subProcesses: SubProcess[],
  topics: TopicForDiscussion[],
  status: Status,
  title: string,
  jdacode?: string
) => {
  const columnNames = [
    'Process',
    'Sub-Process',
    'Finding',
    'Priority',
    'Key Business Risk',
    'Recommendation',
    'Action Plan',
    'Due date',
    'Supervisor',
  ];

  const rowsData = subProcesses.map(sp => [
    `${sp.processCode} - ${sp.processDescription}`,
    sp.description,
    sp.result != null ? sp.result.finding : '',
    sp.result != null ? sp.result.priority : '',
    sp.result != null
      ? sp.result.keyBusinessRisks
          .map(v => `${v}\n`)
          .toLocaleString()
          .replaceAll(',', '')
      : '',
    sp.result != null ? sp.result.recommendation : '',
    sp.actionPlan != null ? sp.actionPlan.description : '',
    sp.actionPlan != null
      ? sp.actionPlan.status === ACTION_PLAN_STATUS.DONE
        ? 'Done'
        : sp.actionPlan.status === ACTION_PLAN_STATUS.NOT_PROGRAMMABLE
        ? 'N/A'
        : `Q${sp.actionPlan.dueDate?.quarter} ${sp.actionPlan.dueDate?.year}`
        ? `Q${sp.actionPlan.dueDate?.quarter} ${sp.actionPlan.dueDate?.year}`
        : ''
      : '',
    sp.actionPlan?.supervisor?.email ?? '',
  ]);

  const topicsColumnNames: string[] = ['Topic for discussion', 'Comments', 'Owner', 'Timestamp'];

  const topicsRowsData: string[][] = [];
  topics.forEach(topic => {
    topic.comments.forEach((comment, index) => {
      const date = new Date(comment.created);
      topicsRowsData.push([
        index === 0 ? topic.title : '',
        comment.message,
        comment.author.name,
        date.toLocaleString(),
      ]);
    });
  });

  const sheets = [{ columnNames, rowsData, sheetName: 'Findings', header: `${jdacode}_${title}` }];

  if (topicsRowsData.length !== 0 || status?.code !== STATUS.SENT_TO_THE_STORES.code) {
    sheets.push({
      columnNames: topicsColumnNames,
      rowsData: topicsRowsData,
      sheetName: 'Topics',
      header: `${jdacode}_${title}`,
    });
  }

  const { workbook } = XLSXUtils.createXlsMultiSheet(sheets);

  const findingsWorksheet = workbook.worksheets.find(sheet => sheet.name === 'Findings');
  if (findingsWorksheet) {
    XLSXUtils.adjustColumnWidth(findingsWorksheet);
    styleWrapAndPositionCell(findingsWorksheet);
    styleHeaderTable(findingsWorksheet);
    styleHeader(findingsWorksheet);
    findingsWorksheet.getColumn('Key Business Risk').width = 30;
    stylePriorityColor(findingsWorksheet);
  }

  const topicsWorksheet = workbook.worksheets.find(sheet => sheet.name === 'Topics');
  if (topicsWorksheet) {
    XLSXUtils.adjustColumnWidth(topicsWorksheet);
    styleWrapAndPositionCell(topicsWorksheet);
    styleHeaderTable(topicsWorksheet);
    styleHeader(topicsWorksheet);
    let startIndex = 4;
    topics.forEach(topic => {
      topicsWorksheet.mergeCells(startIndex, 0, startIndex + topic.comments.length - 1, 0);
      startIndex += topic.comments.length;
    });
  }

  XLSXUtils.downloadXls(workbook, title);
};

const stylePriorityColor = (worksheet: Worksheet) => {
  // priority colors
  // eslint-disable-next-line no-param-reassign
  worksheet.getColumn('Priority').eachCell(
    // eslint-disable-next-line no-return-assign
    (cell: Cell, rowNumber: number) =>
      rowNumber > 3
        ? // eslint-disable-next-line no-param-reassign
          (cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb:
                cell.value === Priority.HIGH
                  ? 'FFff5050'
                  : cell.value === Priority.MEDIUM
                  ? 'FFffff75'
                  : cell.value === Priority.SIGNIFICANT
                  ? 'FFffc000'
                  : cell.value === Priority.LOW
                  ? 'FF5ac66c'
                  : '',
            },
          })
        : ''
  );
};

const styleWrapAndPositionCell = (worksheet: Worksheet) => {
  // Iterate over all rows that have values in a worksheet
  worksheet.eachRow((row, rowNumber) => {
    // controllo per non wrappare anche l'intestazione
    row.eachCell({ includeEmpty: true }, cell => {
      // eslint-disable-next-line no-param-reassign
      cell.alignment =
        rowNumber > 3
          ? { vertical: 'middle', horizontal: 'center', wrapText: true }
          : { vertical: 'middle', horizontal: 'center' };
    });
    // eslint-disable-next-line no-param-reassign
    row.font = { name: 'Arial', size: 10 };
  });
  if (worksheet.name === 'Findings') {
    // eslint-disable-next-line no-param-reassign
    worksheet.getColumn('Finding').alignment = { vertical: 'middle', wrapText: true };
    // eslint-disable-next-line no-param-reassign
    worksheet.getColumn('Recommendation').alignment = { vertical: 'middle', wrapText: true };
    // eslint-disable-next-line no-param-reassign
    worksheet.getColumn('Action Plan').alignment = { vertical: 'middle', wrapText: true };
  }
};

const styleHeaderTable = (worksheet: Worksheet) => {
  const tableHeader = worksheet.getRow(3);
  tableHeader.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFD3D3D3',
    },
  };
  tableHeader.font = { name: 'Arial', size: 10, bold: true };
  tableHeader.border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };
  tableHeader.alignment = { vertical: 'middle', horizontal: 'center' };
};

const styleHeader = (worksheet: Worksheet) => {
  // merge cell here...after all manipulation
  worksheet.mergeCells('A1', 'I1');
  const header = worksheet.getRow(1);
  header.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FF191970',
    },
  };
  header.alignment = { vertical: 'middle', horizontal: 'left' };
  header.font = { name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFFF' } };
};

export const generatePdfDocument = async (
  assessmentDetail: AssessmentDetail,
  document: ReactElement<ReactPDF.Document>,
  fileName: string,
  onFinish?: () => void
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const blob = await pdf(document)
    .toBlob()
    .finally(() => (onFinish ? onFinish() : null));
  saveAs(blob, `${fileName}_${getDateString()}`);
};
