import { css, SerializedStyles } from '@emotion/react';
import style from './field.style';

const freeTextTitle = (isValid: boolean): SerializedStyles => css`
  ${style.freeTextTitle(isValid)}
`;

const validationIcon = css`
  ${style.validationIcon}
`;

const field = css`
  ${style.field}
  overflow: hidden;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const editableField = css`
  ${style.labeledEditableField}
`;

const editIcon = css`
  ${style.editIcon}
`;

const modal = css`
  ${style.modal}
`;

const modalContent = css`
  ${style.modalContent};
  &&& {
    padding: 0 !important;
    flex-direction: column;
    max-height: 450px;
    overflow: unset;
    > div {
      overflow: unset;
    }
  }
`;

const inputContainer = css`
  &&&& {
    > div {
      width: 100%;
    }
    input {
      border-color: var(--saa-primary-color-10);
    }
  }
`;

const input = css`
  &&&&&& {
    input {
      line-height: 1.21428571em;
      padding: 0.67857143em 2.1em 0.67857143em 1em;
      font-size: 1.143rem;
    }
  }
`;

const nameContainer = css`
  ${input};
  ${inputContainer};
  padding-top: 10px;
  .ui.left.corner.label:after {
    top: -1px;
  }
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${style.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const valueContainer = css`
  ${style.valueContainer}
`;

const value = css`
  ${style.value}
`;

const supervisorForm = css`
  &&& {
    padding: 10px;
  }
  border-bottom: 1px solid var(--saa-primary-color-40);
`;

export default {
  field,
  editableField,
  modal,
  modalContent,
  actions,
  button,
  editIcon,
  nameContainer,
  valueContainer,
  value,
  supervisorForm,
  input,
  freeTextTitle,
  validationIcon,
};
