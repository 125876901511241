/** @jsxImportSource @emotion/react */
import React from 'react';
import { t } from 'i18next';
import { Brand, brandCodeList, toBrand } from '../../../shared/model/brand.model';
import { ModalMultiSelectField, SelectItem } from '../../../shared/ModalMultiSelectField';

interface BrandMultiSelectFieldProps {
  defaultValue?: string[];
  onUpdate?: (brand: string[]) => void;
}

export function BrandMultiSelectField({ defaultValue = [], onUpdate }: BrandMultiSelectFieldProps) {
  const toSelectItem = (item: Brand): SelectItem => {
    return {
      code: item.code,
      description: item.description,
      logo: item.logo,
    };
  };

  return (
    <ModalMultiSelectField
      editable
      icon='copyright'
      defaultValue={defaultValue != null ? defaultValue.map(b => toSelectItem(toBrand(b))) : []}
      list={brandCodeList.map((b): SelectItem => toSelectItem(toBrand(b)) as SelectItem)}
      title={t('users.brands') || ''}
      onUpdateValue={(selectedBrand: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedBrand.map(i => i.code));
        }
      }}
    />
  );
}
