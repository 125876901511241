/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Button, Checkbox, Icon, Modal } from 'semantic-ui-react';
import style from './dueDateQuarterSelectionModal.style';
import { Quarter } from '../model/assessment';

interface DueDateQuarterSelectionModalProps {
  title: string;
  value: Quarter | null;    
  editable?: boolean;       
  onChange?: (value: Quarter | null) => void; 
  isValid?: boolean;       
}

export const DueDateQuarterSelectionModal = ({
  title,
  value,
  editable = false,
  onChange = () => null,
  isValid = true,
}: DueDateQuarterSelectionModalProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [selectedQuarter, setSelectedQuarter] = React.useState<Quarter | null>(value);

  const getUpcomingQuarters = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
  
    const currentQuarter = Math.floor(currentMonth / 3) + 1;
  
    return Array(4).fill(0).map((_, i) => {
      const quarter = (currentQuarter + i) % 4 + 1;
      const year = currentYear + Math.floor((currentQuarter + i) / 4);
      return { quarter, year}
    });
  };

  const [quarters, setQuarters] = React.useState<Quarter[]>(getUpcomingQuarters());

  useEffect(() => {
    setQuarters(getUpcomingQuarters());
  }, []);

  useEffect(() => {
    if (value) {
      setSelectedQuarter(value);
    } else {
      setSelectedQuarter(null);
    }
  }, [quarters, value]);

  const updateValue = () => {
    if (selectedQuarter) {
      onChange(selectedQuarter);
    }
    setOpen(false);
  };

  const removeValue = () => {
    onChange(null);
    setSelectedQuarter(null);
    setOpen(false);
  };

  return (
    <>
      <LabeledField
        setOpen={setOpen}
        title={title}
        isValid={isValid}
        value={selectedQuarter}
        editable={editable}
      />
      <Modal css={style.modal} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div>
            {quarters.map(q => (
              <div key={`${q.quarter}-${q.year}`} css={style.checkBoxContainer}>
                <Checkbox
                css={style.checkBox}
                  label={`Q${q.quarter} - ${q.year}`}
                  checked={selectedQuarter?.quarter === q.quarter && selectedQuarter?.year === q.year}
                  onChange={() => setSelectedQuarter(q)}
                />
              </div>
            ))}
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={removeValue}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={updateValue}>
            <Icon name="checkmark" /> OK
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

interface LabeledFieldProps {
  value: Quarter | null; 
  editable: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  isValid: boolean;
}

const LabeledField = ({ title, editable, setOpen, value, isValid }: LabeledFieldProps): JSX.Element => {
  return (
    <div>
      <div css={style.freeTextTitle(isValid)} title={title}>
        {title}
      </div>
      <div css={editable ? style.labeledEditableField : style.labeledField} onClick={() => editable && setOpen(true)}>
        <div>
          {value != null ? `Q${value?.quarter} - ${value?.year}` : ''}{' '}
          {!isValid && <Icon name='exclamation circle' css={style.validationIcon} />}{' '}
        </div>
        {editable && (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        )}
      </div>
    </div>
  );
};
