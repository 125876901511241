/** @jsxImportSource @emotion/react */
import React from 'react';
import { t } from 'i18next';
import { ModalSelectField, SelectItem } from '../../../../shared/ModalSelectField';
import { Region } from '../../../../shared/model/region.model';
import { Country } from '../../../../shared/model/country.model';

interface CountryFieldProps {
  defaultValue?: Country;
  countryList: Country[];
  editable?: boolean;
  onSelect?: (country: Country) => void;
  loading?: boolean;
}

export const CountryField = ({
  defaultValue,
  countryList,
  editable = false,
  onSelect,
  loading = false,
}: CountryFieldProps): JSX.Element => {
  const toSelectItem = (item: Region): SelectItem | undefined => {
    if (item)
      return {
        code: item.code,
        description: item.description,
      };
    return undefined;
  };

  const toCountry = (item: SelectItem): Country => {
    return {
      code: item.code,
      description: item.description,
    };
  };

  return (
    <ModalSelectField
      icon='globe'
      defaultValue={defaultValue != null ? toSelectItem(defaultValue) : undefined}
      list={countryList.map((r): SelectItem => toSelectItem(r) as SelectItem)}
      loading={loading}
      title={t('auditCampaigns.creation.country') || ''}
      editable={editable}
      onSelect={(selectedBrand: SelectItem) => {
        if (onSelect) {
          onSelect(toCountry(selectedBrand));
        }
      }}
    />
  );
};
