import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import type { AppThunk, RootState } from '../../../core/store';
import { checkValueInclude } from '../../../shared/utils';
import api from '../utils/api';
import { AuditProposalWithCampaignInfo } from '../model/auditProposal';
import { covertFiltersInQueryParams, emptyProposalsFilters, ProposalsFilters } from '../model/proposalsQueryParams';
import { Brand, sortBrandList } from '../../../shared/model/brand.model';
import { Store } from '../../../shared/model/store.model';

interface DateRange {
  from: string;
  to: string;
}

interface AuditProposalsAgendaSliceState {
  proposals: AuditProposalWithCampaignInfo[];
  isFetching: boolean;
  error: string;
  dateRange: DateRange;
  textFilter: string;
  filters: ProposalsFilters;
  brands: Brand[];
  regions: string[];
  countries: string[];
  cities: string[];
  stores: Store[];
}

const initialState: AuditProposalsAgendaSliceState = {
  proposals: [],
  isFetching: false,
  error: '',
  textFilter: '',
  dateRange: {
    from: '',
    to: '',
  },
  filters: emptyProposalsFilters,
  brands: [],
  regions: [],
  countries: [],
  cities: [],
  stores: [],
};

export const auditProposalsAgendaSlice = createSlice({
  name: 'auditProposalsAgenda',
  initialState,
  reducers: {
    startFetch: (state: Draft<AuditProposalsAgendaSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (
      state: Draft<AuditProposalsAgendaSliceState>,
      { payload }: PayloadAction<AuditProposalWithCampaignInfo[]>
    ) => ({
      ...state,
      isFetching: false,
      proposals: payload,
      error: '',
    }),
    httpError: (state: Draft<AuditProposalsAgendaSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    setTextFilter: (state: Draft<AuditProposalsAgendaSliceState>, action: PayloadAction<string>) => ({
      ...state,
      textFilter: action.payload,
    }),
    setDateRange: (state: Draft<AuditProposalsAgendaSliceState>, action: PayloadAction<DateRange>) => ({
      ...state,
      dateRange: action.payload,
    }),
    setFilters: (state: Draft<AuditProposalsAgendaSliceState>, action: PayloadAction<ProposalsFilters>) => ({
      ...state,
      filters: action.payload,
    }),
    finishBrandFetch: (state: Draft<AuditProposalsAgendaSliceState>, action: PayloadAction<Brand[]>) => ({
      ...state,
      isFetching: false,
      brands: [...action.payload],
      error: '',
    }),
    finishRegionFetch: (state: Draft<AuditProposalsAgendaSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      regions: [...action.payload],
      error: '',
    }),
    finishCountriesFetch: (state: Draft<AuditProposalsAgendaSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      countries: [...action.payload],
      error: '',
    }),
    finishCitiesFetch: (state: Draft<AuditProposalsAgendaSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      cities: [...action.payload],
      error: '',
    }),
    finishStoresFetch: (state: Draft<AuditProposalsAgendaSliceState>, action: PayloadAction<Store[]>) => ({
      ...state,
      isFetching: false,
      stores: [...action.payload],
      error: '',
    }),
  },
});

export const {
  startFetch,
  finishFetch,
  httpError,
  setTextFilter,
  setDateRange,
  setFilters,
  finishBrandFetch,
  finishRegionFetch,
  finishCountriesFetch,
  finishCitiesFetch,
  finishStoresFetch,
} = auditProposalsAgendaSlice.actions;

export default auditProposalsAgendaSlice.reducer;

export const fetchAuditProposals =
  (from: Date, to: Date): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const dateRange = { from: moment(from).format('YYYY-MM-DD'), to: moment(to).format('YYYY-MM-DD') };
      const proposals = await api.getAuditProposals(dateRange.from, dateRange.to);
      dispatch(setDateRange(dateRange));
      dispatch(finishFetch(proposals));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchProposalsBrands =
  (filters: ProposalsFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const brands: Brand[] = await api.getProposalsBrands(covertFiltersInQueryParams(filters));
      dispatch(finishBrandFetch(brands));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchProposalsRegions =
  (filters: ProposalsFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const regions: string[] = await api.getProposalsRegions(covertFiltersInQueryParams(filters));
      dispatch(finishRegionFetch(regions));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchProposalsCountries =
  (filters: ProposalsFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const countries: string[] = await api.getProposalsCountries(covertFiltersInQueryParams(filters));
      dispatch(finishCountriesFetch(countries));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchProposalsCities =
  (filters: ProposalsFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const cities: string[] = await api.getProposalsCities(covertFiltersInQueryParams(filters));
      dispatch(finishCitiesFetch(cities));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchProposalsStores =
  (filters: ProposalsFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const stores: Store[] = await api.getProposalsStores(covertFiltersInQueryParams(filters));
      dispatch(finishStoresFetch(stores));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateTextFilter =
  (filter: string): AppThunk =>
  (dispatch: any) => {
    dispatch(setTextFilter(filter));
  };

export const selectIsFetching = (state: RootState) => state.auditProposalsAgenda.isFetching;

export const selectProposals = (state: RootState) => state.auditProposalsAgenda.proposals;
export const selectProposalsTextFilter = (state: RootState) => state.auditProposalsAgenda.textFilter;
export const selectProposalsFilters = (state: RootState) => state.auditProposalsAgenda.filters;

export const selectFilteredSortedProposals = createSelector(
  selectProposals,
  selectProposalsTextFilter,
  selectProposalsFilters,
  (proposals, textFilter, filters) => {
    const filteredProposals = proposals.filter(p => checkProposalFilter(p, textFilter, filters));
    return filteredProposals.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
  }
);
export const selectProposalsBrands = (state: RootState) => [...state.auditProposalsAgenda.brands].sort(sortBrandList);

export const selectProposalsRegions = (state: RootState) => state.auditProposalsAgenda.regions;

export const selectProposalsCountries = (state: RootState) => state.auditProposalsAgenda.countries;

export const selectProposalsCities = (state: RootState) => state.auditProposalsAgenda.cities;

export const selectProposalsStores = (state: RootState) => state.auditProposalsAgenda.stores;

export const selectDateRange = (state: RootState) => state.auditProposalsAgenda.dateRange;

const checkProposalFilter = (
  proposal: AuditProposalWithCampaignInfo,
  textFilter: string,
  filters: ProposalsFilters
): boolean => {
  return (
    (checkValueInclude(proposal.store.name, textFilter) ||
      checkValueInclude(proposal.store.brand.description, textFilter) ||
      checkValueInclude(proposal.store.jdaCode, textFilter) ||
      checkValueInclude(proposal.store.hfmCode, textFilter) ||
      checkValueInclude(proposal.store.city, textFilter) ||
      checkValueInclude(proposal.status, textFilter) ||
      proposal.auditors.some(auditor => checkValueInclude(auditor.email, textFilter)) ||
      proposal.auditors.some(auditor => checkValueInclude(auditor.name, textFilter)) ||
      proposal.distributionList.some(distributionListValue =>
        checkValueInclude(distributionListValue.email, textFilter)
      ) ||
      checkDateInInterval(proposal.proposedDateFrom, proposal.proposedDateTo, textFilter)) &&
    (filters.brands.length === 0 || filters.brands.some(b => b.code === proposal.store.brand.code)) &&
    (filters.regions.length === 0 || filters.regions.includes(proposal.store.keringRegion)) &&
    (filters.countries.length === 0 || filters.countries.includes(proposal.store.keringCountry.description)) &&
    (filters.cities.length === 0 || filters.cities.includes(proposal.store.city)) &&
    (filters.jdaCodes.length === 0 || filters.jdaCodes.includes(proposal.store.jdaCode))
  );
};

const checkDateInInterval = (from: string, to: string, value: string): boolean => {
  const dateString = value.replace(/[-/]*$/, '');
  const date = new Date(dateString);
  if (date.toDateString() === 'Invalid Date') {
    return false;
  }

  const fromDate = new Date(from);
  const toDate = new Date(to);

  if (dateString.length === 4) {
    return fromDate.getFullYear() >= date.getFullYear() && date.getFullYear() <= toDate.getFullYear();
  }
  if (dateString.length === 6 || dateString.length === 7) {
    return (
      fromDate.getFullYear() >= date.getFullYear() &&
      date.getFullYear() <= toDate.getFullYear() &&
      date.getMonth() >= fromDate.getMonth() &&
      date.getMonth() <= toDate.getMonth()
    );
  }

  return date >= fromDate && date <= toDate;
};
