/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { Accordion, AccordionContent, AccordionTitle, Icon, Loader } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { useAppDispatch } from '../../../core/store';
import {
  fetchAssessmentsFollowUp,
  onChangeFilter,
  selectAssessmentsFollowUpFilter,
  selectGroupedAssessmentsFollowUp,
  selectIsFetching,
} from '../store/assessmentsFollowUpSlice';
import { SearchBox } from '../../../shared/SearchBox';
import style from './assessmentsFollowUp.style';
import { FollowUpStatus } from '../model/assessmentDetail.model';
import { getDate } from '../../../core/utils';
import VirtualAssessmentList from '../components/VirtualAssessmentList';

export const AssessmentsFollowUp = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isFetching = useSelector(selectIsFetching);
  const groupedAssessment = useSelector(selectGroupedAssessmentsFollowUp);
  const [activeIndex, setActiveIndex] = useState(0);

  const onActiveIndexChange = (index: number) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    dispatch(fetchAssessmentsFollowUp());
  }, [dispatch]);

  return (
    <div css={style.container}>
      <Header />
      {isFetching ? (
        <Loader active />
      ) : (
        <div css={style.cardsContainer}>
          <Accordion>
            {Object.entries(groupedAssessment).map(([status, assessments], index) => (
              <div key={status}>
                <AccordionTitle
                  active={activeIndex === index}
                  index={index}
                  onClick={(event, data) => onActiveIndexChange(data.index as number)}>
                  <h5 css={style.sectionTitle}>
                    <Icon name='dropdown' />
                    {t(`assessment.followUpStatus.${status}`)}
                    {status === FollowUpStatus.COMPLETED ? ` in ${getDate().getFullYear()}` : ''} ({assessments.length})
                  </h5>
                </AccordionTitle>
                <AccordionContent active={activeIndex === index}>
                  <VirtualAssessmentList assessmentList={assessments} />
                </AccordionContent>
              </div>
            ))}
          </Accordion>

          {Object.entries(groupedAssessment).length === 0 && <div css={style.notFound}>{t('noItems')}</div>}
        </div>
      )}
    </div>
  );
};

const Header = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const filter = useSelector(selectAssessmentsFollowUpFilter, () => true);
  const debounced = useDebouncedCallback((value: string) => {
    dispatch(onChangeFilter(value));
  }, 500);

  return (
    <div css={style.header}>
      <div css={style.searchBoxContainer}>
        <SearchBox defaultValue={filter} onChange={value => debounced(value)} />
      </div>
    </div>
  );
};
