import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import api from '../utils/api';
import { Country } from '../../../shared/model/country.model';
import type { RootState } from '../../../core/store';

interface KeringCountriesSliceState {
  data: Country[];
  isFetching: boolean;
  error: string;
  isValidCache: boolean;
}

export const initialState: KeringCountriesSliceState = {
  data: [],
  isFetching: false,
  error: '',
  isValidCache: false,
};

const keringCountriesSlice = createSlice({
  name: 'keringCountries',
  initialState,
  reducers: {
    startFetch: (state: Draft<KeringCountriesSliceState>): KeringCountriesSliceState => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (
      state: Draft<KeringCountriesSliceState>,
      { payload }: PayloadAction<Country[]>
    ): KeringCountriesSliceState => {
      return {
        isFetching: false,
        data: [...payload],
        error: '',
        isValidCache: true,
      };
    },
    errorFetch: (
      state: Draft<KeringCountriesSliceState>,
      { payload }: PayloadAction<string>
    ): KeringCountriesSliceState => ({
      ...state,
      isFetching: false,
      error: payload,
    }),
    invalidateCache: (state: Draft<KeringCountriesSliceState>): KeringCountriesSliceState => ({
      ...state,
      isValidCache: false,
    }),
    reset: (): KeringCountriesSliceState => initialState,
  },
});
export const { startFetch, finishFetch, errorFetch, invalidateCache, reset } = keringCountriesSlice.actions;

export const fetchKeringCountries = (regions: string[]) => async (dispatch: any, state: any) => {
  if (!state().keringCountries.isFetching) {
    dispatch(startFetch());
    try {
      const keringCountries = (await api.getKeringCountries({ regions })).sort((r1, r2) =>
        r1.description.localeCompare(r2.description)
      );
      dispatch(finishFetch(keringCountries));
    } catch (error) {
      dispatch(errorFetch(JSON.stringify(error)));
    }
  }
};

export const clearKeringCountries = () => async (dispatch: any, state: any) => {
  if (!state().keringCountries.isFetching) {
    dispatch(reset());
  }
};

export const selectKeringCountries = (state: RootState): Country[] => state.keringCountries.data;

export const selectIsFetchingKeringCountries = (state: RootState) => state.keringCountries.isFetching;

export default keringCountriesSlice.reducer;
