/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import {
  ACTION_PLAN_STATUS,
  Audit,
  FollowUpStatus,
  getMergingProcessError,
  getMergingSubProcessError,
  hasActionPlanCoordinatorNoteByProcess,
  hasAuditorNoteByProcess,
  hasManagerNote,
  hasManagerNoteByProcess,
  hasRegionalManagerNote,
  hasRegionalManagerNoteByProcess,
  MERGING_PROCESS_STATUS,
  Process,
  ResultStatus,
  ResultUpdateRequest,
  SubProcess,
  SubProcessError,
} from '../model/assessmentDetail.model';
import style from './auditDetail.style';
import { ProcessItem } from './ProcessItem';
import { ResultItem } from './ResultItem';
import { ErrorResultItem } from './ErrorResultItem';
import { ModalPage } from '../../../shared/ModalPage';
import { CheckList } from './CheckList';
import { STATUS } from '../model/assessmentStatus';
import { saveSubProcessResult, selectIsAuditorOwner, selectIsBrandCoordinator, selectIsInReadOnlyDistributionList } from '../store/assessmentDetailSlice';
import { toastService } from '../../../core/services/toastService';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { checkRole, ROLES } from '../../auth/model/principal.model';
import { useAppDispatch } from '../../../core/store';
import { AuditMultiToggle } from './AuditMultiToggle';
import { TopicContainer } from './TopicContainer';
import { isValidFollowUpStatus } from '../model/assessmentFollowUp.model';

interface AuditDetailMergeProps {
  audit: Audit;
  followUpStatus: FollowUpStatus;
}

export const MergingAuditDetail = ({ audit, followUpStatus }: AuditDetailMergeProps): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [onlyFindingsFilter, setOnlyFindingsFilter] = useState(false);
  const [onlyFollowUpFilter, setOnlyFollowUpFilter] = useState(false);
  const [onlyFollowUpToFillInFilter, setOnlyFollowUpToFillInFilter] = useState(false);
  const isFollowUpToFillInToggleVisible = followUpStatus === FollowUpStatus.UNDER_FOLLOWUP
  const [isTopicContainerActive, setIsTopicContainerActive] = useState<boolean>(false);
  const principal = useSelector(selectPrincipal);
  const isAuditAdmin = checkRole(principal, ROLES.AUDIT_ADMIN);
  const isAPCoordinator = useSelector(selectIsBrandCoordinator);
  const isInDistributionList = useSelector(selectIsInReadOnlyDistributionList);

  const isFollowUpToggleVisible = [
    FollowUpStatus.DONE,
    FollowUpStatus.PENDING,
    FollowUpStatus.COMPLETED,
    FollowUpStatus.UNDER_FOLLOWUP,
  ].includes(followUpStatus);

  const filterByFollowUp = useCallback((subProcess: SubProcess): boolean => {
    return subProcess.followUp != null && subProcess.followUp.result != null;
  }, []);

  const getSubProcessError = useCallback((subProcess: SubProcess) => getMergingSubProcessError(subProcess), []);

  const filterByFindings = useCallback(
    (sp: SubProcess): boolean => {
      return getSubProcessError(sp) != null || sp.result.status === ResultStatus.FAILED;
    },
    [getSubProcessError]
  );

  const canEditFollowUp = useCallback((subProcess: SubProcess): boolean => {
    const isAPEditor = principal.email != null && subProcess.actionPlan?.editor?.email === principal.email;
    return (
      (subProcess.followUp != null && isValidFollowUpStatus(followUpStatus)) &&
      (subProcess.actionPlan?.status === ACTION_PLAN_STATUS.SCHEDULED) &&
      (followUpStatus === FollowUpStatus.UNDER_FOLLOWUP) &&
      (isAPCoordinator || isAPEditor || isAuditAdmin || isInDistributionList) &&
      (!subProcess.followUp?.completed) 
      )
  }, [followUpStatus, isAPCoordinator, isAuditAdmin, isInDistributionList, principal.email])

  const filterByFollowUpToFillIn = useCallback((subProcess: SubProcess): boolean => {
    return canEditFollowUp(subProcess)
   }, [canEditFollowUp])

  const getFilteredProcesses = useCallback(() => {
    if (audit == null) {
      return [];
    }
    const hasFilters = onlyFindingsFilter || onlyFollowUpFilter || onlyFollowUpToFillInFilter;
    if (!hasFilters) {
      return audit.processes;
    }
    return audit.processes.filter(
      p =>
        (onlyFindingsFilter ? p.subProcesses.some(filterByFindings) : true) &&
        (onlyFollowUpFilter ? p.subProcesses.filter(filterByFollowUp) : true) &&
        (onlyFollowUpToFillInFilter ? p.subProcesses.filter(filterByFollowUpToFillIn) : true)
    );
  }, [audit, filterByFindings, onlyFindingsFilter, filterByFollowUp, onlyFollowUpFilter, filterByFollowUpToFillIn, onlyFollowUpToFillInFilter]);

  const filterSubProcess = useCallback(
    (sp: SubProcess) => {
      const hasFilters = onlyFindingsFilter || onlyFollowUpFilter || onlyFollowUpToFillInFilter;
      if (!hasFilters) {
        return true;
      }
      return (onlyFindingsFilter ? filterByFindings(sp) : true) && (onlyFollowUpFilter ? filterByFollowUp(sp) : true) && (onlyFollowUpToFillInFilter ? filterByFollowUpToFillIn(sp) : true);
    },
    [filterByFindings, filterByFollowUp, filterByFollowUpToFillIn, onlyFindingsFilter, onlyFollowUpFilter, onlyFollowUpToFillInFilter]
  );

  const getFilteredSubProcess = useCallback(
    (p: Process) => p.subProcesses.filter(filterSubProcess),
    [filterSubProcess]
  );

  const onChangeTopicActive = () => {
    setIsTopicContainerActive(!isTopicContainerActive);
    setActiveIndex(-1);
  };

  const onChangeProcessActive = (index: number) => {
    setIsTopicContainerActive(false);
    setActiveIndex(index);
  };

  return audit != null ? (
    <>
      <div css={style.findingsFilter}>
        <AuditMultiToggle
          editable
          defaultFindingsValue={onlyFindingsFilter}
          defaultFollowUpValue={onlyFollowUpFilter}
          defaultFollowUpToFillInValue={onlyFollowUpToFillInFilter}
          defaultPriorityFilter={[]}
          showFindings
          showFollowUp={isFollowUpToggleVisible}
          showFollowUpToFillIn={isFollowUpToFillInToggleVisible}
          showPriorityFilter={false}
          onFindingsChange={value => {
            setOnlyFindingsFilter(value);
          }}
          onFollowUpChange={value => {
            setOnlyFollowUpFilter(value);
          }}
          onFollowUpToFillInChange={value => {
            setOnlyFollowUpToFillInFilter(value);
          }}
        />
      </div>
      <div css={style.body}>
        {getFilteredProcesses().map((p, index) => (
          <ProcessItem
            process={p}
            index={index}
            key={p.code}
            activeIndex={activeIndex}
            setActiveIndex={onChangeProcessActive}
            icon={
              <>
                {hasManagerNoteByProcess(p) ? <Icon name='sticky note' css={style.noteIcon} /> : <></>}
                {hasAuditorNoteByProcess(p) && <Icon name='sticky note' css={style.auditorNoteIcon} />}
                {hasRegionalManagerNoteByProcess(p) && <Icon name='sticky note' css={style.regionalManagerNoteIcon} />}
                {hasActionPlanCoordinatorNoteByProcess(p) && (
                  <Icon name='sticky note' css={style.actionPlanCoordinatorNoteIcon} />
                )}
                {getMergingProcessError(p) === MERGING_PROCESS_STATUS.SUCCESS ? (
                  <Icon name='check circle' css={style.successIcon} />
                ) : (
                  <Icon name='exclamation circle' css={style.errorIcon} />
                )}
              </>
            }>
            {getFilteredSubProcess(p).map(sp => (
              <SubProcessContainer key={sp.code} subProcess={sp} processCode={p.code} />
            ))}
          </ProcessItem>
        ))}
        <TopicContainer onChangeTopicActive={onChangeTopicActive} isTopicContainerActive={isTopicContainerActive} />
      </div>
    </>
  ) : (
    <></>
  );
};

interface SubProcessItemProps {
  subProcess: SubProcess;
  processCode: string;
}

const SubProcessContainer = ({ subProcess, processCode }: SubProcessItemProps): JSX.Element => {
  const getError = useCallback(() => getMergingSubProcessError(subProcess), [subProcess]);
  const [isCheckListOpen, setCheckListOpen] = useState(false);
  const user = useSelector(selectPrincipal);
  const isAuditorOwner = useSelector(selectIsAuditorOwner) || checkRole(user, ROLES.AUDIT_ADMIN);
  const dispatch = useAppDispatch();

  const handleOnSave = useCallback(
    async (request: ResultUpdateRequest) => {
      const response = await dispatch(saveSubProcessResult(request));
      if (response != null) toastService.success();
      setCheckListOpen(false);
    },
    [dispatch]
  );

  const canEditChecklist = useCallback(
    () => isAuditorOwner && getError() !== SubProcessError.MERGE,
    [getError, isAuditorOwner]
  );

  return (
    <>
      {isCheckListOpen ? (
        <ModalPage onClose={() => setCheckListOpen(false)} title={`${subProcess.code} ${subProcess.description}`}>
          <CheckList
            result={subProcess.result}
            assessmentStatus={STATUS.MERGING}
            subProcessCode={subProcess.code}
            processCode={processCode}
            onSave={handleOnSave}
            readonly={false}
            subProcessInfo={subProcess.info}
            subProcessAvailableKeyBusinessRisks={subProcess.availableKeyBusinessRisks}
          />
        </ModalPage>
      ) : (
        <>
          <div css={style.subProcess} onClick={() => canEditChecklist() && setCheckListOpen(true)}>
            {canEditChecklist() && <Icon name='pencil' css={style.subProcessActionIcon} />}
            <div css={style.subProcessDescription}>
              {subProcess.code} - {subProcess.description}
            </div>
            {hasManagerNote(subProcess) && <Icon name='sticky note' css={style.noteIcon} />}
            {hasRegionalManagerNote(subProcess) && <Icon name='sticky note' css={style.regionalManagerNoteIcon} />}
            {getError() != null ? (
              <Icon name='exclamation circle' css={style.errorIcon} />
            ) : (
              <Icon name='check circle' css={style.successIcon} />
            )}
          </div>
          {getError() == null ? (
            <ResultItem subProcessCode={subProcess.code} result={subProcess.result} subProcessInfo={subProcess.info} />
          ) : isAuditorOwner ? (
            <ErrorResultItem subProcess={subProcess} error={getError() as SubProcessError} />
          ) : subProcess.result.status === ResultStatus.TO_DO ? (
            subProcess.auditingResults
              .filter(r => r.status !== ResultStatus.TO_DO)
              .map(r => (
                <ResultItem key={r.id} subProcessCode={subProcess.code} result={r} subProcessInfo={subProcess.info} />
              ))
          ) : (
            <ResultItem subProcessCode={subProcess.code} result={subProcess.result} subProcessInfo={subProcess.info} />
          )}
        </>
      )}
    </>
  );
};
