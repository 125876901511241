/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionContent, AccordionTitle, Button, Icon, Loader } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { VariableSizeList as List } from 'react-window';
import {
  fetchAssessments,
  selectAssessments,
  selectGroupedAssessments,
  selectIsFetchingAssessments,
  selectIsOpen,
} from '../store/assessmentsSlice';
import style from './assessments.style';
import { STATUS, StatusCode } from '../model/assessmentStatus';
import { change, selectAssessmentsFilter } from '../store/filterSlice';
import { onCloseCreation, selectIsCreating, startCreation } from '../store/onCreationSlice';
import { AssessmentCreation } from '../components/AssessmentCreation';
import { ModalPage } from '../../../shared/ModalPage';
import { SearchBox } from '../../../shared/SearchBox';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { AUDITOR_STAFF_ROLES, checkRoles } from '../../auth/model/principal.model';
import { useAppDispatch } from '../../../core/store';
import { getDate, useWindowSize } from '../../../core/utils';
import { AssessmentFilter, FiltersBadgesContainer } from '../components/assessmentFilter/AssessmentFilter';
import VirtualAssessmentList from '../components/VirtualAssessmentList';
import { Assessment } from '../model/assessment';

interface AssessmentsByStatus {
  assessments: Assessment[];
  status: StatusCode;
}

export const Assessments = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const size = useWindowSize();

  const assessmentsList = useSelector(selectAssessments);
  const assessmentsGroupedByStatus = useSelector(selectGroupedAssessments);
  const isFetching = useSelector(selectIsFetchingAssessments);
  const isCreating = useSelector(selectIsCreating);
  const isFilterOpen = useSelector(selectIsOpen);
  const showLoader = isFetching && !isCreating;

  const assessmentsListRef = useRef<List>(null);
  const statusList = useMemo(() => Object.keys(assessmentsGroupedByStatus), [assessmentsGroupedByStatus]);
  const [activeIndex, setActiveIndex] = useState(0);

  const onActiveIndexChange = (index: number) => {
    setActiveIndex(index);
  };

  const getAssessmentsByStatuses = useCallback(
    (): AssessmentsByStatus[] =>
      statusList
        .map((key): AssessmentsByStatus | null => {
          const assessmentsGroup = assessmentsGroupedByStatus[key as StatusCode];
          if (!assessmentsGroup || assessmentsGroup.length === 0) {
            return null;
          }
          return {
            status: key as StatusCode,
            assessments: assessmentsGroup,
          };
        })
        .filter((item: AssessmentsByStatus | null): item is AssessmentsByStatus => item != null),
    [assessmentsGroupedByStatus, statusList]
  );

  useEffect(() => {
    setTimeout(() => {
      if (assessmentsListRef.current != null) {
        assessmentsListRef.current.resetAfterIndex(0);
      }
    }, 0);
  }, [size, assessmentsGroupedByStatus]);

  useEffect(() => {
    dispatch(fetchAssessments());
  }, [dispatch]);

  return (
    <div css={style.container}>
      {showLoader && <Loader active />}
      {!showLoader && isFilterOpen && <AssessmentFilter assessments={assessmentsList} />}
      {!showLoader && !isFilterOpen && (
        <>
          <Header />
          <div css={style.cardsContainer}>
            <Accordion css={style.accordion}>
              {getAssessmentsByStatuses().map((assessmentsByStatus, index) => {
                const { status, assessments } = assessmentsByStatus;
                const count = assessments.length;
                return (
                  <>
                    <AccordionTitle
                      active={activeIndex === index}
                      index={index}
                      onClick={(event, data) => onActiveIndexChange(data.index as number)}>
                      <h5 css={style.sectionTitle(status as StatusCode)}>
                        <Icon name='dropdown' />
                        {t(`assessment.status.${status}`)}
                        {status === STATUS.CLOSED.code ? ` in ${getDate().getFullYear()}` : ''} ({count})
                      </h5>
                    </AccordionTitle>
                    <AccordionContent ref={assessmentsListRef} active={activeIndex === index}>
                      <VirtualAssessmentList assessmentList={assessments} />
                    </AccordionContent>
                  </>
                );
              })}
            </Accordion>
          </div>
        </>
      )}
    </div>
  );
};

const Header = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const creation = useSelector(selectIsCreating);
  const user = useSelector(selectPrincipal);
  const isAuditor = useCallback(() => checkRoles(user, AUDITOR_STAFF_ROLES), [user]);
  const dispatchCreation = useCallback(() => dispatch(startCreation()), [dispatch]);
  const closeCreation = useCallback(() => {
    dispatch(onCloseCreation());
  }, [dispatch]);

  return (
    <div css={style.header}>
      {isAuditor() && <Button onClick={dispatchCreation} icon='plus' css={style.addButton} />}
      <SearchAssessmentBox />
      {creation ? (
        <ModalPage onClose={closeCreation} title={t('assessment.creation.title') || ''}>
          <AssessmentCreation />
        </ModalPage>
      ) : (
        <></>
      )}
    </div>
  );
};

const SearchAssessmentBox = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const filter = useSelector(selectAssessmentsFilter, () => true);
  const debounced = useDebouncedCallback((value: string) => {
    dispatch(change(value));
  }, 500);

  return (
    <div css={style.searchBoxContainer}>
      <FiltersBadgesContainer />
      <SearchBox defaultValue={filter} onChange={value => debounced(value)} />
    </div>
  );
};
