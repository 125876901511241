import { ROLES } from '../../auth/model/principal.model';
import { BrandCode } from '../../../shared/model/brand.model';
import { Country } from '../../../shared/model/country.model';
import { Region } from '../../../shared/model/region.model';

export const getEmptyUser = (): User => ({
  id: '',
  email: '',
  policiesAccepted: false,
  firstName: '',
  familyName: '',
  roles: [],
  brands: [],
  approvalRequired: false,
  isRegionalManagerOf: [],
  regions: [],
  countries: [],
});

export interface User {
  id: string;
  email: string;
  firstName: string;
  familyName: string;
  policiesAccepted: boolean;
  roles: ROLES[];
  brands: BrandCode[];
  approvalRequired: boolean;
  isRegionalManagerOf: string[];
  regions: string[];
  countries: Country[];
}

export const toUser = (user: Record<string, unknown>): User => {
  return {
    id: user.id as string,
    email: user.email as string,
    firstName: user.firstName as string,
    familyName: user.familyName as string,
    policiesAccepted: user.policiesAccepted as boolean,
    roles: user.roles as ROLES[],
    brands: user.brands as BrandCode[],
    approvalRequired: user.approvalRequired as boolean,
    isRegionalManagerOf: user.isRegionalManagerOf as string[],
    regions: user.regions as string[],
    countries: user.countries as Country[],
  };
};

export const toAdUser = (o: Record<string, unknown>): AdUser => {
  return {
    email: o.mail as string,
    firstName: o.givenName as string,
    familyName: o.surname as string,
    validBrands: o.brands as BrandCode[],
    jobTitle: o.jobTitle as string,
  };
};

export interface UserUpdateRequest {
  roles: ROLES[];
  brands: BrandCode[];
  approvalRequired: boolean;
  isRegionalManagerOf: string[];
  regions: string[] | null;
  countries: string[] | null;
}

export interface AdUser {
  email: string;
  firstName: string;
  familyName: string;
  validBrands: BrandCode[];
  jobTitle?: string;
}

export interface UserCreationRequest {
  email: string;
  firstName: string;
  familyName: string;
  roles: ROLES[];
  brands: BrandCode[];
  approvalRequired: boolean;
  isRegionalManagerOf: string[];
  regions: string[] | null;
  countries: string[] | null;
}

export interface Contact {
  name: string;
  email: string;
  regions?: Region[];
  countries?: Country[];
  jobTitle?: string;
}

export interface UserFilters {
  brands: string[];
  roles: string[];
  regions: string[];
  countries: Country[];
}

export const emptyUsersFilters = {
  brands: [],
  roles: [],
  regions: [],
  countries: [],
};

export const emptyContact = (): Contact => ({
  name: '',
  email: '',
});

export const toContact = (o: Record<string, unknown>): Contact => {
  return o != null
    ? {
        name: o.name as string,
        email: o.email as string,
        regions: o.regions as Region[],
        countries: o.regions as Country[],
        jobTitle: o.jobTitle as string,
      }
    : { name: '', email: '' };
};
