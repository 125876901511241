/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Dimmer, Icon, Loader, Modal } from 'semantic-ui-react';
import { Store } from '../../../../shared/model/store.model';
import { useAppDispatch } from '../../../../core/store';
import { SelectAvailableStores } from '../../../assessments/components/SelectAvailableStores';
import { createCampaign, createProposal, selectCampaign } from '../../store/auditProposalWizardSlice';
import { reset as resetStore } from '../../../assessments/store/availableStoresSlice';
import { AuditCampaign, AuditCampaignRequest } from '../../model/auditCampaign';
import { AuditProposal, AuditProposalRequest } from '../../model/auditProposal';
import { Auditor } from '../../../assessments/model/assessment';
import { ButtonConfiguration } from '../WizardFooter';
import { StepSegment } from '../StepSegment';
import { selectPrincipal } from '../../../auth/store/principalSlice';
import style from '../../../assessments/components/actionPlanEditorSelector.style';
import { AuditProposalDetailFields } from '../proposalDetail/auditProposalDetail/AuditProposalDetailFields';
import { INTERNAL_AUDIT_PROPOSAL_STATUS } from '../../model/genericAuditProposal';

interface ProposalCreationStepProps {
  onSuccessfulCreation: () => void;
  onBack: () => void;
}

export const ProposalCreationStep = ({ onSuccessfulCreation, onBack }: ProposalCreationStepProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const principal = useSelector(selectPrincipal);
  const campaign = useSelector(selectCampaign) as AuditCampaign;

  const [selectedStore, setSelectedStore] = useState<Store | null>(null);
  const [auditors, setAuditors] = useState<Auditor[]>([]);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [isDuplicatedStore, setIsDuplicatedStore] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState(false);

  const proposal: AuditProposal = useMemo(
    () => ({
      id: '',
      store: selectedStore as Store,
      proposedDateFrom: dateFrom,
      proposedDateTo: dateTo,
      proposedByBrandDateFrom: '',
      proposedByBrandDateTo: '',
      proposedPeriods: [],
      brandSchedulers: campaign.brandSchedulers,
      distributionList: campaign.distributionList,
      auditors,
      status: INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT,
      createdBy: { name: '', email: '' },
      createdAt: '',
      type: 'internal',
    }),
    [campaign.brandSchedulers, campaign.distributionList, selectedStore, dateFrom, dateTo, auditors]
  );

  const onStoreSelect = useCallback(
    (store: Store) => {
      setSelectedStore(store);
      dispatch(resetStore());
    },
    [dispatch]
  );

  const buildProposalRequest = useCallback((): AuditProposalRequest => {
    return {
      storeJdaCode: (selectedStore as Store).jdaCode,
      proposedDateFrom: dateFrom,
      proposedDateTo: dateTo,
      brandSchedulers: campaign.brandSchedulers.map(c => c.email),
      distributionList: campaign.distributionList.map(c => c.email),
      auditors: auditors.map(c => c.email),
    };
  }, [selectedStore, dateFrom, dateTo, campaign.brandSchedulers, campaign.distributionList, auditors]);

  const buildCampaignWithProposalRequest = useCallback((): AuditCampaignRequest => {
    return {
      name: campaign.name,
      brand: campaign.brand.code,
      region: campaign.region.description,
      owner: principal.email,
      brandSchedulers: campaign.brandSchedulers.map(c => c.email),
      distributionList: campaign.distributionList.map(c => c.email),
      proposals: [buildProposalRequest()],
    };
  }, [
    campaign.name,
    campaign.brand,
    campaign.region,
    campaign.brandSchedulers,
    campaign.distributionList,
    principal.email,
    buildProposalRequest,
  ]);

  const create = useCallback(async () => {
    setIsCreating(true);
    let campaignId: string;
    let proposalId: string;

    if (campaign.id) {
      const createdProposal = await dispatch(createProposal(campaign.id, buildProposalRequest()));
      campaignId = campaign.id;
      proposalId = createdProposal?.id ?? '';
    } else {
      const createdCampaign = await dispatch(createCampaign(buildCampaignWithProposalRequest()));
      campaignId = createdCampaign?.id ?? '';
      proposalId = createdCampaign?.proposals[0].id ?? '';
    }
    setIsCreating(false);
    if (campaignId !== '' && proposalId !== '') {
      onSuccessfulCreation();
      navigate(`/audit-campaigns/${campaignId}/internal-audit-proposals/${proposalId}`);
    }
  }, [campaign.id, dispatch, onSuccessfulCreation, buildCampaignWithProposalRequest, buildProposalRequest, navigate]);

  const canCreate = useMemo(
    () => selectedStore != null && dateFrom !== '' && dateTo !== '' && new Date(dateFrom) <= new Date(dateTo),
    [dateFrom, dateTo, selectedStore]
  );

  const setCalendar = (from: string, to: string) => {
    setDateFrom(from);
    setDateTo(to);
  };

  const buttonsConfig = useMemo((): ButtonConfiguration[] => {
    return [
      {
        content: t('auditCampaigns.creation.back'),
        onClick: () => {
          if (selectedStore != null) {
            setSelectedStore(null);
          } else {
            onBack();
          }
        },
        icon: 'angle left',
        labelPosition: 'left',
      },
      {
        content: t('auditCampaigns.creation.create'),
        onClick: create,
        icon: 'save',
        labelPosition: 'left',
        disabled: !canCreate,
      },
    ];
  }, [t, create, canCreate, selectedStore, onBack]);

  useEffect(() => {
    const campaignAlreadyHasStore = campaign.proposals.find(campaignProposal => {
      return (
        campaignProposal.status !== INTERNAL_AUDIT_PROPOSAL_STATUS.DELETED &&
        campaignProposal.store.jdaCode === selectedStore?.jdaCode
      );
    });
    setIsDuplicatedStore(campaignAlreadyHasStore != null);
  }, [campaign, selectedStore]);

  return (
    <StepSegment footerConfiguration={buttonsConfig}>
      {isCreating ? (
        <Dimmer active inverted>
          <Loader inverted content='Loading' />
        </Dimmer>
      ) : (
        <></>
      )}
      {selectedStore == null || isDuplicatedStore ? (
        <div style={{ marginTop: '10px', marginBottom: '10px', overflow: 'auto', height: 'calc(100% - 10px)' }}>
          <SelectAvailableStores
            onStoreSelect={onStoreSelect}
            brandCode={campaign.brand.code}
            region={campaign.region.description}
            country={campaign.country?.code}
          />
        </div>
      ) : (
        <AuditProposalDetailFields
          editable
          campaignId={campaign.id}
          proposal={proposal}
          onCalendarChange={setCalendar}
          onAuditorsChange={setAuditors}
        />
      )}
      <Modal css={style.modal} open={isDuplicatedStore}>
        <Modal.Header>{t('Warning')}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div css={style.inputContainer}>
            <p>
              {`Be aware, there is an already existing assessment on 
              ${selectedStore?.name ? selectedStore?.name : 'this'} 
              store. Delete the existing proposal to proceed.`}
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            css={style.button}
            onClick={() => {
              setSelectedStore(null);
              setIsDuplicatedStore(false);
            }}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </StepSegment>
  );
};
