/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { ModalSelectField, SelectItem } from '../../../../shared/ModalSelectField';
import { Contact } from '../../../users/model/user.model';
import { useAppDispatch } from '../../../../core/store';
import { fetchBrandSchedulers } from '../../store/brandSchedulersSlice';
import { BrandScheduler } from '../../model/brandScheduler';

interface BrandSchedulersFieldProps {
  defaultValue?: Contact;
  brandSchedulers: BrandScheduler[];
  editable?: boolean;
  onSelect?: (contact: Contact) => void;
  loading?: boolean;
  assessmentBrand: string | undefined;
  assessmentRegion?: string;
  assessmentCountry?: string;
}

export function BrandSchedulersField({
  defaultValue,
  brandSchedulers,
  editable = false,
  onSelect,
  loading = false,
  assessmentBrand,
  assessmentRegion,
  assessmentCountry,
}: BrandSchedulersFieldProps): JSX.Element {
  const dispatch = useAppDispatch();

  const toSelectItem = (item: Contact): SelectItem | undefined => {
    if (!item) return undefined;

    const subDescription =
      item.countries && item.countries.length > 0
        ? item.countries.map(c => c.description).join(', ')
        : item.regions && item.regions.length > 0
        ? item.regions.map(c => c.description).join(', ')
        : undefined;

    return {
      code: item.email,
      description: item.name,
      subdescription: subDescription,
    };
  };

  const toBrandScheduler = (item: SelectItem): Contact => {
    return {
      email: item.code,
      name: item.description,
    };
  };

  useEffect(() => {
    if (editable && !!assessmentBrand) {
      dispatch(fetchBrandSchedulers({ brand: assessmentBrand, region: assessmentRegion, country: assessmentCountry }));
    }
  }, [dispatch, editable, assessmentBrand, assessmentRegion, assessmentCountry]);

  return (
    <ModalSelectField
      iconClassName='fas fa-user-tie'
      defaultValue={defaultValue != null ? toSelectItem(defaultValue) : undefined}
      loading={loading}
      list={brandSchedulers.map((b: Contact): SelectItem => toSelectItem(b) as SelectItem)}
      title={t('auditCampaigns.creation.brandSchedulers') || ''}
      editable={editable}
      onSelect={(selectedBrand: SelectItem) => {
        if (onSelect) {
          onSelect(toBrandScheduler(selectedBrand));
        }
      }}
    />
  );
}
