/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Checkbox, Radio } from 'semantic-ui-react';
import _ from 'lodash';
import { useAppDispatch } from '../../../core/store';
import style from './actionPlan.style';
import { AuditNotes } from './AuditNotes';
import { FreeTextField } from './FreeTextField';
import {
  ACTION_PLAN_STATUS,
  ActionPlan as ActionPlanModel,
  ActionPlanSubmitRequest,
  ActionPlanUpdateRequest,
  AttachmentRef,
  PictureRef,
  Priority,
} from '../model/assessmentDetail.model';
import { checkRole, checkRoles, ROLES } from '../../auth/model/principal.model';
import { Status as AssessmentStatus, STATUS } from '../model/assessmentStatus';
import { toastService } from '../../../core/services/toastService';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { SubProcessFooter } from './SubProcessFooter';
import { DueDateQuarterSelectionModal } from './DueDateQuarterSelectionModal';
import {
  assignActionPlanEditor,
  saveActionPlanBrandNote,
  savePlanAuditorNoteAction,
  selectIsAuditorOwner,
  selectIsBrandCoordinator,
  selectIsEditStoreUser,
} from '../store/assessmentDetailSlice';
import { ActionPlanEditor, Supervisor } from '../model/assessment';
import { AttachmentsListView } from './AttachmentsListView';
import { setUnsavedChangesPresence } from '../../../core/unsavedChangesSlice';
import { SupervisorField } from './SupervisorField';

interface ActionPlanProps {
  actionPlan: ActionPlanModel;
  assessmentStatus: AssessmentStatus;
  processCode: string;
  subProcessCode: string;
  onSubmit?: (updateRequest: ActionPlanUpdateRequest, submitRequest: ActionPlanSubmitRequest) => void;
  onSave?: (request: ActionPlanUpdateRequest) => void;
  readonly?: boolean;
  subProcessResultPriority: Priority;
}

export function ActionPlan({
  actionPlan,
  assessmentStatus,
  processCode,
  subProcessCode,
  onSubmit = () => null,
  onSave = () => null,
  readonly = true,
  subProcessResultPriority,
}: ActionPlanProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useSelector(selectPrincipal);
  const [status, setStatus] = useState(actionPlan.status);
  const [description, setDescription] = useState(actionPlan.description);
  const [dueDate, setDueDate] = useState(actionPlan.dueDate);

  const [supervisor, setSupervisor] = useState<Supervisor | null>(actionPlan.supervisor);

  const [submitted] = useState(actionPlan.submitted);
  const isAuditorOwner = useSelector(selectIsAuditorOwner);
  const isAuditAdmin = checkRole(user, ROLES.AUDIT_ADMIN);

  const isBrandCoordinator = useSelector(selectIsBrandCoordinator);
  const isEditStoreUser = useSelector(selectIsEditStoreUser);
  const [pictures, setPictures] = useState(actionPlan.pictures);
  const [attachments, setAttachments] = useState(actionPlan.attachments);
  const [attachmentsNotMandatory, setAttachmentsNotMandatory] = useState(actionPlan.attachmentsNotMandatory);

  const isAPEditor = useMemo(
    () => actionPlan.editor?.email?.toLowerCase() === user.email.toLowerCase(),
    [actionPlan, user]
  );

  const deletePicture = useCallback((picture: PictureRef) => {
    setPictures(prevState => prevState.filter(p => p.id !== picture.id));
  }, []);

  const deleteAttament = useCallback((attachment: AttachmentRef) => {
    setAttachments(prevState => prevState.filter(p => p.id !== attachment.id));
  }, []);

  const canEditAuditorNote = useCallback(() => {
    return (
      (checkRoles(user, [ROLES.AUDIT_ADMIN, ROLES.AUDITOR_MANAGER]) || isAuditorOwner) &&
      STATUS.ACTIONS_PLAN_UNDER_REVIEW.code === assessmentStatus.code
    );
  }, [user, isAuditorOwner, assessmentStatus.code]);

  const showAuditorNote = useCallback(() => {
    return canEditAuditorNote() || (actionPlan.auditorNote !== '' && actionPlan.auditorNote != null);
  }, [actionPlan.auditorNote, canEditAuditorNote]);

  const updateAuditorNote = useCallback(
    async (note: string) => {
      const response =
        processCode &&
        subProcessCode &&
        (await dispatch(savePlanAuditorNoteAction({ note, processCode, subProcessCode })));
      if (response != null) toastService.success();
    },
    [dispatch, processCode, subProcessCode]
  );

  const updateBrandNote = useCallback(
    async (note: string) => {
      const response =
        processCode &&
        subProcessCode &&
        (await dispatch(saveActionPlanBrandNote({ note, processCode, subProcessCode })));
      if (response != null) toastService.success();
    },
    [dispatch, processCode, subProcessCode]
  );

  const canSubmit = useMemo(() => !readonly && isAPEditor && !submitted, [readonly, isAPEditor, submitted]);

  const canSave = useMemo(
    () => !readonly && (isEditStoreUser || isAuditAdmin || (isAPEditor && !submitted)),
    [readonly, isEditStoreUser, isAuditAdmin, isAPEditor, submitted]
  );

  const dueDateEditable = useMemo(() => canSave && status === ACTION_PLAN_STATUS.SCHEDULED, [status, canSave]);
  const supervisorEditable = useMemo(
    () => canSave && status !== ACTION_PLAN_STATUS.NOT_PROGRAMMABLE,
    [status, canSave]
  );

  const attachmentsAreMandatory = useCallback(
    () =>
      status === ACTION_PLAN_STATUS.DONE && [Priority.HIGH, Priority.SIGNIFICANT].includes(subProcessResultPriority),
    [status, subProcessResultPriority]
  );

  const actionPlanUpdateRequest = useMemo(
    (): ActionPlanUpdateRequest => ({
      processCode,
      subProcessCode,
      status,
      description,
      dueDate: dueDateEditable ? dueDate : null,
      supervisor: supervisorEditable ? supervisor : null,
      pictures: status === ACTION_PLAN_STATUS.DONE ? pictures : [],
      attachments: status === ACTION_PLAN_STATUS.DONE ? attachments : [],
      attachmentsNotMandatory: attachmentsAreMandatory() ? attachmentsNotMandatory : false,
    }),
    [
      processCode,
      subProcessCode,
      status,
      description,
      dueDateEditable,
      dueDate,
      supervisorEditable,
      supervisor,
      pictures,
      attachments,
      attachmentsAreMandatory,
      attachmentsNotMandatory,
    ]
  );

  const saveActionPlan = useCallback(async () => {
    onSave(actionPlanUpdateRequest);
    setDueDate(actionPlanUpdateRequest.dueDate);
    setSupervisor(actionPlanUpdateRequest.supervisor);
    setPictures(actionPlanUpdateRequest.pictures);
    setAttachments(actionPlanUpdateRequest.attachments);
    setAttachmentsNotMandatory(actionPlanUpdateRequest.attachmentsNotMandatory);
  }, [onSave, actionPlanUpdateRequest]);

  const handleSubmitEditor = useCallback(async () => {
    const submitRequest: ActionPlanSubmitRequest = {
      processCode,
      subProcessCode,
      submitted: true,
    };

    if (onSubmit) {
      onSubmit(actionPlanUpdateRequest, submitRequest);
      setDueDate(actionPlanUpdateRequest.dueDate);
      setSupervisor(actionPlanUpdateRequest.supervisor);
    }
  }, [processCode, subProcessCode, onSubmit, actionPlanUpdateRequest]);

  const canAssign = useMemo(() => !readonly && isBrandCoordinator, [readonly, isBrandCoordinator]);

  const onAssign = useCallback(
    async (editor: ActionPlanEditor) => {
      const { name, email } = editor;
      const response =
        processCode &&
        subProcessCode &&
        canAssign &&
        (await dispatch(assignActionPlanEditor({ processCode, subProcessCode, name, email })));
      if (response != null) toastService.success();
    },
    [dispatch, processCode, subProcessCode, canAssign]
  );
  const mainFieldsAreFilled = useMemo(
    () =>
      description != null &&
      description !== '' &&
      (status !== ACTION_PLAN_STATUS.SCHEDULED || dueDate != null) &&
      (status === ACTION_PLAN_STATUS.NOT_PROGRAMMABLE || supervisor != null) &&
      (!attachmentsAreMandatory() || attachments.length > 0 || pictures.length > 0 || attachmentsNotMandatory),
    [
      attachments.length,
      attachmentsAreMandatory,
      attachmentsNotMandatory,
      description,
      dueDate,
      pictures.length,
      status,
      supervisor,
    ]
  );

  const isFilled = useCallback(() => {
    return readonly || mainFieldsAreFilled;
  }, [mainFieldsAreFilled, readonly]);

  const canEditBrandNote = useCallback(() => {
    return (isEditStoreUser || isAPEditor) && STATUS.SENT_TO_THE_STORES.code === assessmentStatus.code;
  }, [isEditStoreUser, isAPEditor, assessmentStatus.code]);

  const showBrandNote = useCallback(() => {
    const isValidStatus = [STATUS.ACTIONS_PLAN_UNDER_REVIEW.code, STATUS.CLOSED.code].includes(assessmentStatus.code);

    return (
      (canEditBrandNote() && mainFieldsAreFilled) ||
      (isValidStatus && actionPlan.brandNote !== '' && actionPlan.brandNote != null)
    );
  }, [actionPlan.brandNote, assessmentStatus.code, canEditBrandNote, mainFieldsAreFilled]);

  useEffect(() => {
    if (
      _.isEqual(actionPlan.status, status) &&
      _.isEqual(actionPlan.description, description) &&
      _.isEqual(actionPlan.dueDate, dueDate) &&
      _.isEqual(actionPlan.supervisor, supervisor) &&
      _.isEqual(actionPlan.pictures, pictures) &&
      _.isEqual(actionPlan.attachments, attachments) &&
      actionPlan.attachmentsNotMandatory === attachmentsNotMandatory
    ) {
      dispatch(setUnsavedChangesPresence(false));
    } else {
      dispatch(setUnsavedChangesPresence(true));
    }
  }, [
    actionPlan.attachments,
    actionPlan.attachmentsNotMandatory,
    actionPlan.description,
    actionPlan.dueDate,
    actionPlan.pictures,
    actionPlan.status,
    actionPlan.supervisor,
    attachments,
    attachmentsNotMandatory,
    description,
    dispatch,
    dueDate,
    pictures,
    status,
    supervisor,
  ]);

  return (
    <div css={style.container}>
      <div css={style.content}>
        {(showAuditorNote() || showBrandNote()) && (
          <div css={style.notesContainer}>
            {showAuditorNote() && (
              <div css={style.auditNoteContainer}>
                <AuditNotes
                  defaultValue={actionPlan.auditorNote}
                  title={t('assessment.actionPlan.auditorNote')}
                  editable={canEditAuditorNote()}
                  onEdited={note => updateAuditorNote(note)}
                  type='auditor'
                />
              </div>
            )}
            {showBrandNote() && (
              <div css={style.brandNoteContainer}>
                <AuditNotes
                  defaultValue={actionPlan.brandNote}
                  title={t('assessment.actionPlan.brandNote')}
                  editable={canEditBrandNote() && mainFieldsAreFilled}
                  onEdited={note => updateBrandNote(note)}
                  disabled={canEditBrandNote() && !mainFieldsAreFilled}
                  type='brand'
                />
              </div>
            )}
          </div>
        )}
        <div css={style.freeTextContainer}>
          <StatusBox status={status} onChange={value => setStatus(value)} readonly={readonly} />
        </div>
        <div css={style.freeTextContainer}>
          <FreeTextField
            title={t('assessment.actionPlan.description')}
            defaultValue={description}
            editable={canSave}
            onChange={value => setDescription(value)}
            isValid={readonly || (description != null && description !== '')}
          />
        </div>
        <div css={style.disableField(status !== ACTION_PLAN_STATUS.SCHEDULED)}>
          <DueDateQuarterSelectionModal
            title={t('assessment.actionPlan.dueDate')}
            value={status !== ACTION_PLAN_STATUS.SCHEDULED ? null : dueDate}
            editable={dueDateEditable}
            onChange={value => setDueDate(value)}
            isValid={readonly || status !== ACTION_PLAN_STATUS.SCHEDULED || dueDate != null}
          />
        </div>
        <div css={style.disableField(status === ACTION_PLAN_STATUS.NOT_PROGRAMMABLE)}>
          <SupervisorField
            editable={supervisorEditable}
            defaultValue={status !== ACTION_PLAN_STATUS.NOT_PROGRAMMABLE && supervisor != null ? supervisor : undefined}
            onSave={value => setSupervisor(value)}
            isValid={readonly || status === ACTION_PLAN_STATUS.NOT_PROGRAMMABLE || supervisor != null}
          />
        </div>
        <div css={style.disableField(status !== ACTION_PLAN_STATUS.DONE)}>
          <AttachmentsListView
            pictures={status === ACTION_PLAN_STATUS.DONE ? pictures : []}
            attachments={status === ACTION_PLAN_STATUS.DONE ? attachments : []}
            onAddAttachments={attachs => setAttachments(prevState => prevState.concat(attachs))}
            onDeleteAttachment={deleteAttament}
            onDeletePicture={deletePicture}
            onAddPictures={pics => setPictures(prevState => prevState.concat(pics))}
            editable={status === ACTION_PLAN_STATUS.DONE && !readonly}
            isValid={
              readonly ||
              !attachmentsAreMandatory() ||
              attachments.length > 0 ||
              pictures.length > 0 ||
              attachmentsNotMandatory
            }
          />
          {attachmentsAreMandatory() && (
            <div css={style.attachmentsNotMandatoryContainer}>
              <div css={style.attachmentsNotMandatoryLabelContainer}>
                <div css={style.attachmentsNotMandatoryLabel}>{t('assessment.actionPlan.noDocumentsToAttach')}</div>
                <div css={style.attachmentsNotMandatorySubLabel}>
                  {t('assessment.actionPlan.useThisOnlyForExceptionalCases')}
                </div>
              </div>
              <Checkbox
                css={style.attachmentsNotMandatoryToggle}
                toggle
                checked={attachmentsNotMandatory}
                onChange={() => setAttachmentsNotMandatory(!attachmentsNotMandatory)}
                disabled={status !== ACTION_PLAN_STATUS.DONE || readonly}
              />
            </div>
          )}
        </div>
      </div>
      <div css={style.assigneeDetail}>
        <div>
          <span css={style.assigneeDetailTitle}>{t('assessment.actionPlan.assignedTo')}: </span>
          <span css={style.assigneeDetailItem}>
            {actionPlan.editor?.name ? actionPlan.editor.name : t('assessment.actionPlan.unassigned')}
          </span>
        </div>
        {actionPlan.lastUpdate && actionPlan.lastUpdateAuthor?.name && (
          <div css={style.assigneeLastUpdate}>
            <span css={style.assigneeDetailTitle}>{t('assessment.actionPlan.updatedBy')}: </span>
            <span css={style.assigneeDetailItem}>{actionPlan.lastUpdateAuthor?.name}</span>
            <span css={style.assigneeDetailAt}>{t('assessment.actionPlan.at')} </span>
            <span css={style.assigneeDetailItem}>{actionPlan.lastUpdate}</span>
          </div>
        )}
      </div>
      <SubProcessFooter
        isActive={isFilled()}
        onSubmit={handleSubmitEditor}
        onSave={saveActionPlan}
        canSave={canSave}
        actionPlanEditor={actionPlan.editor}
        canSubmit={canSubmit}
        canAssign={canAssign}
        onAssign={onAssign}
      />
    </div>
  );
}

interface StatusBoxProps {
  status: ACTION_PLAN_STATUS;
  onChange?: (value: ACTION_PLAN_STATUS) => void;
  readonly?: boolean;
  name?: string;
}

export function StatusBox({
  status,
  onChange = () => null,
  readonly = true,
  name = 'statusGroup',
}: StatusBoxProps): JSX.Element {
  const { t } = useTranslation();
  const [value, setValue] = useState(status);

  useEffect(() => {
    setValue(status);
  }, [status]);

  return (
    <div css={style.statusContainer}>
      <div css={style.statusTitle}>{t('assessment.actionPlan.status')}</div>
      <div css={style.statusRadioBox}>
        <Radio
          name={name}
          checked={value === ACTION_PLAN_STATUS.SCHEDULED}
          onChange={() => onChange(ACTION_PLAN_STATUS.SCHEDULED)}
          css={style.statusRadioElement}
          disabled={readonly}
          label={t('assessment.actionPlan.scheduled')}
        />
        <Radio
          name={name}
          checked={value === ACTION_PLAN_STATUS.DONE}
          onChange={() => onChange(ACTION_PLAN_STATUS.DONE)}
          css={style.statusRadioElement}
          disabled={readonly}
          label={t('assessment.actionPlan.done')}
        />
        <Radio
          name={name}
          checked={value === ACTION_PLAN_STATUS.NOT_PROGRAMMABLE}
          onChange={() => onChange(ACTION_PLAN_STATUS.NOT_PROGRAMMABLE)}
          css={style.statusRadioElement}
          disabled={readonly}
          label={t('assessment.actionPlan.notProgrammable')}
        />
      </div>
    </div>
  );
}
