import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../../core/store';
import api from '../utils/api';
import {
  AssessmentBulkUpdateFilters,
  covertFiltersInQueryParams,
  emptyAssessmentBulkUpdateFilters,
} from '../model/assessmentBulkUpdateQueryParams';
import { Brand, sortBrandList } from '../../../shared/model/brand.model';
import { Store } from '../../../shared/model/store.model';
import {
  AssessmentBulkUpdate,
  AssessmentBulkUpdateAuditorsEmailsRequest,
  AssessmentBulkUpdateBrandCoordinatorsRequest,
  AssessmentBulkUpdateDistributionListRequest,
  AssessmentBulkUpdateReadOnlyDistributionListRequest,
} from '../model/assessmentBulkUpdate';
import { Auditor, BrandCoordinator } from '../../assessments/model/assessment';
import { Contact } from '../../users/model/user.model';

export type SortOrder = 'ascending' | 'descending';

export interface SortField {
  sortColumn: string;
  sortOrder?: SortOrder;
}

export interface Pagination {
  limit: number;
  page: number;
}

interface AssessmentBulkUpdateSliceState {
  assessments: AssessmentBulkUpdate[];
  isFetching: boolean;
  error: string;
  filters: AssessmentBulkUpdateFilters;
  sort: SortField;
  pagination: Pagination;
  statuses: string[];
  brands: Brand[];
  regions: string[];
  countries: string[];
  cities: string[];
  stores: Store[];
  showResults: boolean;
  followUpStatuses: string[];
  auditors: Auditor[];
  allAuditors: Auditor[];
  actionPlanCoordinators: BrandCoordinator[];
  distributionListMembers: Contact[];
  readOnlyDistributionListMembers: Contact[];
  selectedAssessments: string[];
}

const initialState: AssessmentBulkUpdateSliceState = {
  assessments: [],
  isFetching: false,
  error: '',
  filters: emptyAssessmentBulkUpdateFilters,
  sort: { sortColumn: 'locationName', sortOrder: 'ascending' },
  pagination: { page: 1, limit: 30 },
  statuses: [],
  brands: [],
  regions: [],
  countries: [],
  cities: [],
  stores: [],
  showResults: false,
  followUpStatuses: [],
  auditors: [],
  allAuditors: [],
  actionPlanCoordinators: [],
  distributionListMembers: [],
  readOnlyDistributionListMembers: [],
  selectedAssessments: [],
};

export const assessmentBulkUpdateSlice = createSlice({
  name: 'assessmentBulkUpdate',
  initialState,
  reducers: {
    startFetch: (state: Draft<AssessmentBulkUpdateSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (
      state: Draft<AssessmentBulkUpdateSliceState>,
      { payload }: PayloadAction<AssessmentBulkUpdate[]>
    ) => ({
      ...state,
      isFetching: false,
      assessments: payload,
      error: '',
    }),
    change: (state: Draft<AssessmentBulkUpdateSliceState>, { payload }: PayloadAction<AssessmentBulkUpdate[]>) => {
      return {
        ...state,
        isFetching: false,
        assessments: payload,
        error: '',
      };
    },
    setFilter: (
      state: Draft<AssessmentBulkUpdateSliceState>,
      { payload }: PayloadAction<AssessmentBulkUpdateFilters>
    ) => ({
      ...state,
      filters: payload,
    }),
    setShowResults: (state: Draft<AssessmentBulkUpdateSliceState>, { payload }: PayloadAction<boolean>) => ({
      ...state,
      showResults: payload,
    }),
    setPagination: (state: Draft<AssessmentBulkUpdateSliceState>, { payload }: PayloadAction<Pagination>) => {
      return {
        ...state,
        pagination: payload,
      };
    },
    setSelectedAssessments: (state: Draft<AssessmentBulkUpdateSliceState>, { payload }: PayloadAction<string[]>) => {
      return {
        ...state,
        selectedAssessments: payload,
      };
    },
    setSort: (state: Draft<AssessmentBulkUpdateSliceState>, { payload }: PayloadAction<SortField>) => {
      return {
        ...state,
        sort: payload,
      };
    },
    finishStatusesFetch: (state: Draft<AssessmentBulkUpdateSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      statuses: [...action.payload],
      error: '',
    }),
    finishBrandFetch: (state: Draft<AssessmentBulkUpdateSliceState>, action: PayloadAction<Brand[]>) => ({
      ...state,
      isFetching: false,
      brands: [...action.payload],
      error: '',
    }),
    finishRegionFetch: (state: Draft<AssessmentBulkUpdateSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      regions: [...action.payload],
      error: '',
    }),
    finishCountriesFetch: (state: Draft<AssessmentBulkUpdateSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      countries: [...action.payload],
      error: '',
    }),
    finishCitiesFetch: (state: Draft<AssessmentBulkUpdateSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      cities: [...action.payload],
      error: '',
    }),
    finishStoresFetch: (state: Draft<AssessmentBulkUpdateSliceState>, action: PayloadAction<Store[]>) => ({
      ...state,
      isFetching: false,
      stores: [...action.payload],
      error: '',
    }),
    finishAuditorsFetch: (state: Draft<AssessmentBulkUpdateSliceState>, action: PayloadAction<Auditor[]>) => ({
      ...state,
      isFetching: false,
      auditors: [...action.payload],
      error: '',
    }),
    finishAllAuditorsFetch: (state: Draft<AssessmentBulkUpdateSliceState>, action: PayloadAction<Auditor[]>) => ({
      ...state,
      isFetching: false,
      allAuditors: [...action.payload],
      error: '',
    }),
    finishActionPlanCoordinatorsFetch: (
      state: Draft<AssessmentBulkUpdateSliceState>,
      action: PayloadAction<BrandCoordinator[]>
    ) => ({
      ...state,
      isFetching: false,
      actionPlanCoordinators: [...action.payload],
      error: '',
    }),
    finishDistributionListMembersFetch: (
      state: Draft<AssessmentBulkUpdateSliceState>,
      action: PayloadAction<Contact[]>
    ) => ({
      ...state,
      isFetching: false,
      distributionListMembers: [...action.payload],
      error: '',
    }),
    finishReadOnlyDistributionListMembersFetch: (
      state: Draft<AssessmentBulkUpdateSliceState>,
      action: PayloadAction<Contact[]>
    ) => ({
      ...state,
      isFetching: false,
      readOnlyDistributionListMembers: [...action.payload],
      error: '',
    }),
    finishFollowUpStatusesFetch: (
      state: Draft<AssessmentBulkUpdateSliceState>,
      action: PayloadAction<string[]>
    ): AssessmentBulkUpdateSliceState => ({
      ...state,
      isFetching: false,
      followUpStatuses: [...action.payload],
      error: '',
    }),
    httpError: (state: Draft<AssessmentBulkUpdateSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
  },
});

export const {
  startFetch,
  finishFetch,
  change,
  setFilter,
  setShowResults,
  setPagination,
  setSelectedAssessments,
  setSort,
  finishStatusesFetch,
  finishBrandFetch,
  finishRegionFetch,
  finishCountriesFetch,
  finishCitiesFetch,
  finishStoresFetch,
  finishAuditorsFetch,
  finishAllAuditorsFetch,
  finishActionPlanCoordinatorsFetch,
  finishDistributionListMembersFetch,
  finishReadOnlyDistributionListMembersFetch,
  finishFollowUpStatusesFetch,
  httpError,
} = assessmentBulkUpdateSlice.actions;

export default assessmentBulkUpdateSlice.reducer;

export const fetchAssessmentBulkUpdate =
  (filters: AssessmentBulkUpdateFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const results = await api.getAssessments(covertFiltersInQueryParams(filters));
      dispatch(finishFetch(results));
      dispatch(setSelectedAssessments(results.map(result => result.id)));
      dispatch(setFilter(filters));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const resetAssessmentBulkUpdate = (): AppThunk => async (dispatch: any) => {
  dispatch(startFetch());
  try {
    dispatch(finishFetch([]));
    dispatch(setFilter(emptyAssessmentBulkUpdateFilters));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const onChangePage =
  (page: number): AppThunk =>
  (dispatch, state) => {
    const { pagination } = state().assessmentBulkUpdate;
    if (page !== pagination.page) {
      dispatch(setPagination({ ...pagination, page }));
    }
  };

export const onChangeSelectedAssessments =
  (selectedAssessments: string[]): AppThunk =>
  (dispatch, state) => {
    dispatch(setSelectedAssessments(selectedAssessments));
  };

export const onSort =
  (clickedColumn: string): AppThunk =>
  (dispatch, state) => {
    const { sortColumn, sortOrder } = state().assessmentBulkUpdate.sort;
    const { pagination } = state().assessmentBulkUpdate;

    let newOrder: SortOrder = sortOrder === 'ascending' ? 'descending' : 'ascending';
    if (sortColumn !== clickedColumn) {
      newOrder = 'ascending';
    }

    dispatch(setPagination({ ...pagination, page: 1 }));
    dispatch(setSort({ sortColumn: clickedColumn, sortOrder: newOrder }));
  };

export const fetchStatuses =
  (filters: AssessmentBulkUpdateFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const statuses: string[] = await api.getStatuses(covertFiltersInQueryParams(filters));
      dispatch(finishStatusesFetch(statuses));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchBrands =
  (filters: AssessmentBulkUpdateFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const brands: Brand[] = await api.getBrands(covertFiltersInQueryParams(filters));
      dispatch(finishBrandFetch(brands));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchRegions =
  (filters: AssessmentBulkUpdateFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const regions: string[] = await api.getRegions(covertFiltersInQueryParams(filters));
      dispatch(finishRegionFetch(regions));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchCountries =
  (filters: AssessmentBulkUpdateFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const countries: string[] = await api.getCountries(covertFiltersInQueryParams(filters));
      dispatch(finishCountriesFetch(countries));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchCities =
  (filters: AssessmentBulkUpdateFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const cities: string[] = await api.getCities(covertFiltersInQueryParams(filters));
      dispatch(finishCitiesFetch(cities));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchStores =
  (filters: AssessmentBulkUpdateFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const stores: Store[] = await api.getStores(covertFiltersInQueryParams(filters));
      dispatch(finishStoresFetch(stores));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchFollowUpStatuses =
  (filters: AssessmentBulkUpdateFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const statuses: string[] = await api.getFollowUpStatuses(covertFiltersInQueryParams(filters));
      dispatch(finishFollowUpStatusesFetch(statuses));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchAuditors =
  (filters: AssessmentBulkUpdateFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const auditors: Auditor[] = await api.getAuditors(covertFiltersInQueryParams(filters));
      dispatch(finishAuditorsFetch(auditors));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchAllAuditors = (): AppThunk => async (dispatch: any) => {
  dispatch(startFetch());
  try {
    const auditors: Auditor[] = await api.getAllAuditors();
    dispatch(finishAllAuditorsFetch(auditors));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const fetchActionPlanCoordinators =
  (filters: AssessmentBulkUpdateFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const actionPlanCoordinators: BrandCoordinator[] = await api.getActionPlanCoordinators(
        covertFiltersInQueryParams(filters)
      );
      dispatch(finishActionPlanCoordinatorsFetch(actionPlanCoordinators));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchDistributionListMembers =
  (filters: AssessmentBulkUpdateFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const distributionListMembers: Contact[] = await api.getDistributionListMembers(
        covertFiltersInQueryParams(filters)
      );
      dispatch(finishDistributionListMembersFetch(distributionListMembers));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchReadOnlyDistributionListMembers =
  (filters: AssessmentBulkUpdateFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const readOnlyDistributionListMembers: Contact[] = await api.getReadOnlyDistributionListMembers(
        covertFiltersInQueryParams(filters)
      );
      dispatch(finishReadOnlyDistributionListMembersFetch(readOnlyDistributionListMembers));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const selectIsFetching = (state: RootState): boolean => state.assessmentBulkUpdate.isFetching;

export const selectFilters = (state: RootState): AssessmentBulkUpdateFilters => state.assessmentBulkUpdate.filters;

export const selectSort = (state: RootState) => state.assessmentBulkUpdate.sort;
export const selectPagination = (state: RootState) => state.assessmentBulkUpdate.pagination;

export const selectSelectedAssessments = (state: RootState) => state.assessmentBulkUpdate.selectedAssessments;
export const selectPaginationLimit = (state: RootState) => state.assessmentBulkUpdate.pagination.limit;

export const selectResults = (state: RootState): AssessmentBulkUpdate[] => [...state.assessmentBulkUpdate.assessments];

export const selectSortedAndFilteredResults = createSelector(
  selectResults,
  selectSort,
  selectFilters,
  (results, sort, filters) => {
    const filteredResults = results;
    return sort.sortOrder === 'descending'
      ? _.sortBy(filteredResults, [sort.sortColumn]).reverse()
      : _.sortBy(filteredResults, [sort.sortColumn]);
  }
);

export const selectSortedAndPaginatedResults = createSelector(
  selectSortedAndFilteredResults,
  selectPagination,
  (sortedData, pagination) => {
    const startIndex = pagination.page * pagination.limit - pagination.limit;
    const endIndex = startIndex + pagination.limit;
    return sortedData?.slice(startIndex, endIndex);
  }
);
export const selectTotalPagesAndCount = createSelector(
  selectSortedAndFilteredResults,
  selectPaginationLimit,
  (filteredData, paginationLimit) => {
    return {
      totalPages: filteredData.length > 0 ? Math.ceil(filteredData.length / paginationLimit) : 1,
      totalCount: filteredData?.length || 0,
    };
  }
);

export const selectAuditorsSelectedAssessments = createSelector(
  selectResults,
  selectSelectedAssessments,
  (assessments, selectedAssessments): Auditor[] => {
    const auditors = assessments
      .filter(assessment => selectedAssessments.includes(assessment.id))
      .flatMap(assessment => assessment.auditors);
    return _.uniqBy(auditors, 'email');
  }
);

export const selectActionPlanCoordinatorsSelectedAssessments = createSelector(
  selectResults,
  selectSelectedAssessments,
  (assessments, selectedAssessments): BrandCoordinator[] => {
    const actionPlanCoordinators = assessments
      .filter(assessment => selectedAssessments.includes(assessment.id))
      .flatMap(assessment => assessment.actionPlanCoordinators);
    return _.uniqBy(actionPlanCoordinators, 'email');
  }
);

export const selectDistributionListSelectedAssessments = createSelector(
  selectResults,
  selectSelectedAssessments,
  (assessments, selectedAssessments): Contact[] => {
    const distributionList = assessments
      .filter(assessment => selectedAssessments.includes(assessment.id))
      .flatMap(assessment => assessment.distributionList);
    return _.uniqBy(distributionList, 'email');
  }
);

export const selectReadOnlyDistributionListSelectedAssessments = createSelector(
  selectResults,
  selectSelectedAssessments,
  (assessments, selectedAssessments): Contact[] => {
    const readOnlyDistributionList = assessments
      .filter(assessment => selectedAssessments.includes(assessment.id))
      .flatMap(assessment => assessment.readOnlyDistributionList);
    return _.uniqBy(readOnlyDistributionList, 'email');
  }
);

const getNewAssessments = (
  newAssessments: AssessmentBulkUpdate[],
  oldAssessments: AssessmentBulkUpdate[]
): AssessmentBulkUpdate[] => {
  newAssessments.forEach(newAssessment => {
    const index = oldAssessments.findIndex(oldAssessment => oldAssessment.id === newAssessment.id);
    if (index !== -1) {
      oldAssessments.splice(index, 1, newAssessment);
    }
  });
  return oldAssessments;
};
export const updateAuditors =
  (request: AssessmentBulkUpdateAuditorsEmailsRequest) =>
  async (dispatch: any, state: any): Promise<AssessmentBulkUpdate[] | null> => {
    try {
      const assessments = await api.updateAuditors(request);
      dispatch(change(getNewAssessments(assessments, _.cloneDeep(state().assessmentBulkUpdate.assessments))));
      return assessments;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateActionPlanCoordinators =
  (request: AssessmentBulkUpdateBrandCoordinatorsRequest) =>
  async (dispatch: any, state: any): Promise<AssessmentBulkUpdate[] | null> => {
    try {
      const assessments = await api.updateActionPlanCoordinators(request);
      dispatch(change(getNewAssessments(assessments, _.cloneDeep(state().assessmentBulkUpdate.assessments))));
      return assessments;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateDistributionListMembers =
  (request: AssessmentBulkUpdateDistributionListRequest) =>
  async (dispatch: any, state: any): Promise<AssessmentBulkUpdate[] | null> => {
    try {
      const assessments = await api.updateDistributionListMembers(request);
      dispatch(change(getNewAssessments(assessments, _.cloneDeep(state().assessmentBulkUpdate.assessments))));
      return assessments;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateReadOnlyDistributionListMembers =
  (request: AssessmentBulkUpdateReadOnlyDistributionListRequest) =>
  async (dispatch: any, state: any): Promise<AssessmentBulkUpdate[] | null> => {
    try {
      const assessments = await api.updateReadOnlyDistributionListMembers(request);
      dispatch(change(getNewAssessments(assessments, _.cloneDeep(state().assessmentBulkUpdate.assessments))));
      return assessments;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const selectStatuses = (state: RootState) => state.assessmentBulkUpdate.statuses;
export const selectBrands = (state: RootState) => [...state.assessmentBulkUpdate.brands].sort(sortBrandList);
export const selectRegions = (state: RootState) => state.assessmentBulkUpdate.regions;
export const selectCountries = (state: RootState) => state.assessmentBulkUpdate.countries;
export const selectCities = (state: RootState) => state.assessmentBulkUpdate.cities;
export const selectStores = (state: RootState) => state.assessmentBulkUpdate.stores;
export const selectShowResults = (state: RootState) => state.assessmentBulkUpdate.showResults;
export const selectFollowUpStatuses = (state: RootState) => state.assessmentBulkUpdate.followUpStatuses;

export const selectAuditors = (state: RootState) => state.assessmentBulkUpdate.auditors;

export const selectAllAuditors = (state: RootState) => state.assessmentBulkUpdate.allAuditors;

export const selectActionPlanCoordinators = (state: RootState) => state.assessmentBulkUpdate.actionPlanCoordinators;

export const selectDistributionListMembers = (state: RootState) => state.assessmentBulkUpdate.distributionListMembers;

export const selectReadOnlyDistributionListMembers = (state: RootState) =>
  state.assessmentBulkUpdate.readOnlyDistributionListMembers;
