import moment from 'moment';
import { Brand, toBrand } from '../../../shared/model/brand.model';
import {
  ACTION_PLAN_STATUS,
  auditInternalControlDescription,
  FollowUpStatus,
} from '../../assessments/model/assessmentDetail.model';
import { AuditScoreRating, Supervisor } from '../../assessments/model/assessment';

export interface AssessmentReportResult {
  status: string;
  brand: Brand;
  region: string;
  country: string;
  city: string;
  hfmCode: string;
  jdaCode: string;
  locationName: string;
  year: number;
  month: number;
  processCode: string;
  processDescription: string;
  subProcessCode: string;
  subProcessDescription: string;
  subProcessResultFindings: string;
  subProcessResultPriority: string;
  subProcessResultRecommendation: string;
  subProcessScore: number;
  keyBusinessRisks: string[];
  failedScorePercentage: number;
  rating: string;
  successScorePercentage: number;
  actionPlanStatus: ACTION_PLAN_STATUS;
  actionPlanDescription: string;
  actionPlanSupervisor: Supervisor;
  actionPlanDueDate: string | null;
  followUpStatus: string;
  followUpDescription: string;
  followUpResult: string;
  followUpSupervisor: Supervisor;
  followUpDueDate: string | null;
}

export const toAssessmentReportResults = (a: Record<string, unknown>): AssessmentReportResult[] =>
  (a.processes as Record<string, unknown>[]).flatMap(p =>
    (p.subProcesses as Record<string, unknown>[]).map(sp => toAssessmentReportResult(sp, p, a))
  );

function calculateDueDate(subProcess: Record<string, unknown>): string | null {
  const { actionPlanStatus, actionPlanDueDate } = subProcess;

  let actionPlanDueDateStatus: string | null = null;

  if (actionPlanStatus === ACTION_PLAN_STATUS.DONE) {
    actionPlanDueDateStatus = 'Done';
  } else if (actionPlanStatus === ACTION_PLAN_STATUS.NOT_PROGRAMMABLE) {
    actionPlanDueDateStatus = 'N/A';
  }

  if (actionPlanDueDateStatus) {
    return actionPlanDueDateStatus;
  }

  return actionPlanDueDate ? moment(actionPlanDueDate as string).format('yyyy-MM-DD') : null;
}

const toAssessmentReportResult = (
  subProcess: Record<string, unknown>,
  process: Record<string, unknown>,
  assessment: Record<string, unknown>
): AssessmentReportResult => {
  const actionPlanDueDate = calculateDueDate(subProcess);

  return {
    status: assessment.status as string,
    brand: toBrand(assessment.brand as string),
    region: assessment.region as string,
    country: assessment.country as string,
    city: assessment.city as string,
    hfmCode: assessment.hfmCode as string,
    jdaCode: assessment.jdaCode as string,
    locationName: assessment.locationName as string,
    year: assessment.year as number,
    month: assessment.month as number,
    processCode: process.code as string,
    processDescription: process.description as string,
    subProcessCode: subProcess.code as string,
    subProcessDescription: subProcess.description as string,
    subProcessResultFindings: subProcess.resultFindings as string,
    subProcessResultPriority: subProcess.resultPriority as string,
    subProcessResultRecommendation: subProcess.resultRecommendation as string,
    subProcessScore: subProcess.score as number,
    keyBusinessRisks: subProcess.keyBusinessRisks == null ? [] : (subProcess.keyBusinessRisks as string[]),
    failedScorePercentage: assessment.failedScorePercentage as number,
    rating: auditInternalControlDescription.get(assessment.rating as AuditScoreRating) as string,
    successScorePercentage: assessment.successScorePercentage as number,
    actionPlanStatus: subProcess.actionPlanStatus as ACTION_PLAN_STATUS,
    actionPlanDescription: subProcess.actionPlanDescription as string,
    actionPlanSupervisor: subProcess.actionPlanSupervisor as Supervisor,
    actionPlanDueDate,
    followUpStatus:
      assessment.followUpStatus != null ? (assessment.followUpStatus as FollowUpStatus) : FollowUpStatus.EMPTY,
    followUpDescription: subProcess.followUpDescription as string,
    followUpResult: subProcess.followUpResult as string,
    followUpSupervisor: subProcess.followUpSuperVisor as Supervisor,
    followUpDueDate:
      subProcess.followUpDueDate != null ? moment(subProcess.followUpDueDate as string).format('yyyy-MM-DD') : null,
  };
};
