/** @jsxImportSource @emotion/react */
import { Table } from 'semantic-ui-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import style from './assessmentReportTableHeaderRow.style';
import { selectPrincipal } from '../../../auth/store/principalSlice';
import { checkRoles, ROLES } from '../../../auth/model/principal.model';

interface CardTableHeaderRowProps {
  sortColumn?: string;
  direction?: 'ascending' | 'descending';
  handleSort?(column: string): void;
}
export const AssessmentReportTableHeaderRow: React.FC<CardTableHeaderRowProps> = ({
  sortColumn,
  direction,
  handleSort,
}) => {
  const { t } = useTranslation();
  const getSortingDirection = (column: string): 'ascending' | 'descending' | undefined =>
    sortColumn === column ? direction : undefined;

  const user = useSelector(selectPrincipal);
  const isAuditor = useCallback(() => checkRoles(user, [ROLES.AUDITOR, ROLES.AUDITOR_MANAGER]), [user]);

  return (
    <Table.Header css={style.header}>
      <Table.Row>
        {isAuditor() && (
          <Table.HeaderCell
            width={2}
            singleLine
            sorted={getSortingDirection('status')}
            onClick={() => handleSort && handleSort('status')}>
            {t('assessment.report.columns.status')}
          </Table.HeaderCell>
        )}
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('brand')}
          onClick={() => handleSort && handleSort('brand')}>
          {t('assessment.report.columns.brand')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('region')}
          onClick={() => handleSort && handleSort('region')}>
          {t('assessment.report.columns.region')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('country')}
          onClick={() => handleSort && handleSort('country')}>
          {t('assessment.report.columns.country')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('city')}
          onClick={() => handleSort && handleSort('city')}>
          {t('assessment.report.columns.city')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('hfmCode')}
          onClick={() => handleSort && handleSort('hfmCode')}>
          {t('assessment.report.columns.hfmCode')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('jdaCode')}
          onClick={() => handleSort && handleSort('jdaCode')}>
          {t('assessment.report.columns.jdaCode')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('locationName')}
          onClick={() => handleSort && handleSort('locationName')}>
          {t('assessment.report.columns.locationName')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('year')}
          onClick={() => handleSort && handleSort('year')}>
          {t('assessment.report.columns.year')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('month')}
          onClick={() => handleSort && handleSort('month')}>
          {t('assessment.report.columns.month')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('processCode')}
          onClick={() => handleSort && handleSort('processCode')}>
          {t('assessment.report.columns.processCode')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('processDescription')}
          onClick={() => handleSort && handleSort('processDescription')}>
          {t('assessment.report.columns.processDescription')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('subProcessCode')}
          onClick={() => handleSort && handleSort('subProcessCode')}>
          {t('assessment.report.columns.subProcessCode')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('subProcessDescription')}
          onClick={() => handleSort && handleSort('subProcessDescription')}>
          {t('assessment.report.columns.subProcessDescription')}
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={getSortingDirection('subProcessResultFindings')}
          onClick={() => handleSort && handleSort('subProcessResultFindings')}>
          {t('assessment.report.columns.subProcessResultFindings')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('subProcessResultPriority')}
          onClick={() => handleSort && handleSort('subProcessResultPriority')}>
          {t('assessment.report.columns.subProcessResultPriority')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('subProcessResultPriority')}
          onClick={() => handleSort && handleSort('subProcessResultRecommendation')}>
          {t('assessment.report.columns.subProcessResultRecommendation')}
        </Table.HeaderCell>
        {isAuditor() && (
          <>
            <Table.HeaderCell
              width={2}
              singleLine
              sorted={getSortingDirection('subProcessScore')}
              onClick={() => handleSort && handleSort('subProcessScore')}>
              {t('assessment.report.columns.subProcessScore')}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              singleLine
              sorted={getSortingDirection('failedScorePercentage')}
              onClick={() => handleSort && handleSort('failedScorePercentage')}>
              {t('assessment.report.columns.failedScorePercentage')}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              singleLine
              sorted={getSortingDirection('rating')}
              onClick={() => handleSort && handleSort('rating')}>
              {t('assessment.report.columns.rating')}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              singleLine
              sorted={getSortingDirection('successScorePercentage')}
              onClick={() => handleSort && handleSort('successScorePercentage')}>
              {t('assessment.report.columns.successScorePercentage')}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              singleLine
              sorted={getSortingDirection('keyBusinessRisks')}
              onClick={() => handleSort && handleSort('keyBusinessRisks')}>
              {t('assessment.report.columns.keyBusinessRisks')}
            </Table.HeaderCell>
          </>
        )}
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('actionPlanDescription')}
          onClick={() => handleSort && handleSort('actionPlanDescription')}>
          {t('assessment.report.columns.actionPlanDescription')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('actionPlanSupervisor')}
          onClick={() => handleSort && handleSort('actionPlanSupervisor')}>
          {t('assessment.report.columns.actionPlanSupervisor')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('actionPlanSupervisorRole')}
          onClick={() => handleSort && handleSort('actionPlanSupervisorRole')}>
          {t('assessment.report.columns.actionPlanSupervisorRole')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('actionPlanDueDate')}
          onClick={() => handleSort && handleSort('actionPlanDueDate')}>
          {t('assessment.report.columns.actionPlanDueDate')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('followUpStatus')}
          onClick={() => handleSort && handleSort('followUpStatus')}>
          {t('assessment.report.columns.followUpStatus')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('followUpResult')}
          onClick={() => handleSort && handleSort('followUpResult')}>
          {t('assessment.report.columns.followUpResult')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('followUpDescription')}
          onClick={() => handleSort && handleSort('followUpDescription')}>
          {t('assessment.report.columns.followUpDescription')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('actionPlanSupervisor')}
          onClick={() => handleSort && handleSort('actionPlanSupervisor')}>
          {t('assessment.report.columns.followUpSupervisor')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('actionPlanSupervisorRole')}
          onClick={() => handleSort && handleSort('actionPlanSupervisorRole')}>
          {t('assessment.report.columns.followUpSupervisorRole')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('followUpDueDate')}
          onClick={() => handleSort && handleSort('followUpDueDate')}>
          {t('assessment.report.columns.followUpDueDate')}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};
