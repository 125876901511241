/** @jsxImportSource @emotion/react */
import React from 'react';
import { Icon, Loader, Pagination as Pager, PaginationProps, Table } from 'semantic-ui-react';
import { AssessmentReportTableRow } from './AssessmentReportTableRow';
import { AssessmentReportTableHeaderRow } from './AssessmentReportTableHeaderRow';
import style from './assessmentReportTable.style';
import { AssessmentReportResult } from '../../model/assessmentReportResult';

interface AssessmentReportTableProps {
  elements: AssessmentReportResult[];
  totalPages?: number;
  currentPage?: number;
  sortColumn?: string;
  direction?: 'ascending' | 'descending';
  onChangePage?(page: number): void;
  handleSort?(clickedColumn: string): void;
  onClickRow?(result: AssessmentReportResult): void;
  rowHeight?: number;
  pageSize?: number;
  totalCount?: number;
  isLoadingData?: boolean;
}

export const AssessmentReportTable: React.FC<AssessmentReportTableProps> = ({
  elements,
  totalPages,
  sortColumn,
  currentPage,
  direction,
  handleSort,
  onChangePage,
  onClickRow,
  rowHeight,
  pageSize,
  totalCount,
  isLoadingData,
}) => {
  const rows = elements.map((result, index) => (
    <AssessmentReportTableRow
      key={`${result.jdaCode}-${result.year}-${result.month}-${result.processCode}-${result.subProcessCode}`}
      result={result}
      onClickRow={onClickRow}
      rowHeight={rowHeight}
      cellTitle={false}
    />
  ));
  const handleChangePage = (event: React.MouseEvent<HTMLAnchorElement>, { activePage }: PaginationProps) => {
    if (onChangePage != null) onChangePage(activePage as number);
  };

  return (
    <>
      <div css={style.tableContainer}>
        <Table celled selectable={onClickRow != null} sortable={elements.length > 0} striped compact>
          <AssessmentReportTableHeaderRow sortColumn={sortColumn} direction={direction} handleSort={handleSort} />
          {!isLoadingData && <Table.Body>{rows}</Table.Body>}
        </Table>
        {isLoadingData ? (
          <Loader active style={{ margin: 'auto' }} />
        ) : (
          elements.length === 0 && <div style={{ margin: 'auto', fontWeight: 500 }}>No data</div>
        )}
      </div>
      {elements.length > 0 && totalPages && currentPage && onChangePage && pageSize && totalCount && (
        <div css={style.footer}>
          <Pager
            css={style.paginationContainer}
            totalPages={totalPages}
            activePage={currentPage}
            onPageChange={handleChangePage}
            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
            firstItem={null}
            lastItem={null}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
          />
          <div css={style.counter}>
            <span>
              {(currentPage - 1) * pageSize + 1} - {Math.min(currentPage * pageSize, totalCount)}
            </span>
            <span style={{ fontWeight: 400 }}> of</span>
            <span> {totalCount}</span>
            <span style={{ fontWeight: 400 }}> items</span>
          </div>
        </div>
      )}
    </>
  );
};

export type SortOrder = 'ascending' | 'descending';

export interface SortField {
  sortColumn: string;
  sortOrder?: SortOrder;
}

export interface Pagination {
  limit: number;
  page: number;
}
