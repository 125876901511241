/* eslint-disable import/no-cycle */
import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { Assessment, Auditor, BrandCoordinator, ChangeDetail } from '../model/assessment';
import api from '../utils/api';
import {
  ActionPlanAttachmentsCheckedRequest,
  ActionPlanEditorUpdateRequest,
  ActionPlanSubmitRequest,
  ActionPlanUpdateRequest,
  AssessmentDetail,
  Comment,
  FollowUpAttachmentsCheckedRequest,
  FollowUpStatus,
  FollowUpSubmitRequest,
  FollowUpUpdateRequest,
  ResultUpdateRequest,
  SubProcessNoteRequest,
  TopicForDiscussion,
  TopicForDiscussionRequest,
  TopicForDiscussionUpsertRequest,
  UpdateBrandCoordinatorRequest,
} from '../model/assessmentDetail.model';
import { STATUS, StatusCode } from '../model/assessmentStatus';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { fetchAuditorResults } from './auditorResultsSlice';
import offlineApi from '../utils/offlineApi';
import { AUDITOR_STAFF_ROLES, checkRole, checkRoles, ROLES } from '../../auth/model/principal.model';
import type { AppThunk, RootState } from '../../../core/store';
import { BrandCode } from '../../../shared/model/brand.model';
import { Contact } from '../../users/model/user.model';

interface AssessmentDetailSliceState {
  value: AssessmentDetail | null;
  isFetching: boolean;
  error: string;
  timeout: number | undefined;
}

const initialState = {
  value: null,
  isFetching: false,
  error: '',
  timeout: undefined,
} as AssessmentDetailSliceState;

const pollingTime = 60000; // ms

export const assessmentDetailSlice = createSlice({
  name: 'assessmentDetail',
  initialState,
  reducers: {
    startFetch: (state: Draft<AssessmentDetailSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<AssessmentDetailSliceState>, action: PayloadAction<AssessmentDetail>) => {
      return {
        isFetching: false,
        value: action.payload,
        error: '',
        timeout: state.timeout,
      };
    },
    httpError: (state: Draft<AssessmentDetailSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    reset: () => initialState,
    change: (state: Draft<AssessmentDetailSliceState>, { payload }: PayloadAction<AssessmentDetail>) => {
      return {
        isFetching: false,
        value: payload,
        error: '',
        timeout: state.timeout,
      };
    },
    setTimeoutHandler: (state: Draft<AssessmentDetailSliceState>, { payload }: PayloadAction<number>) => ({
      ...state,
      timeout: payload,
    }),
    clearTimeoutHandler: (state: Draft<AssessmentDetailSliceState>) => {
      if (state.timeout != null) {
        window.clearTimeout(state.timeout);
      }
      return { ...state, timeout: undefined };
    },
  },
});

export const { startFetch, finishFetch, httpError, reset, change, setTimeoutHandler, clearTimeoutHandler } =
  assessmentDetailSlice.actions;

export const fetchAssessmentDetailLoop = (assessmentId: string) => async (dispatch: any, state: any) => {
  await dispatch(fetchAssessmentDetail(assessmentId));
  const timeout = window.setTimeout(() => {
    dispatch(fetchAssessmentDetailLoop(assessmentId));
  }, pollingTime);
  dispatch(setTimeoutHandler(timeout));
};

export const fetchAssessmentDetail = (id: string) => async (dispatch: any, state: any) => {
  dispatch(startFetch());
  try {
    const assessment = await offlineApi.getAssessmentDetail(id);
    dispatch(finishFetch(assessment));
    if (assessment.status.code === STATUS.IN_PROGRESS.code) {
      await dispatch(fetchAuditorResults(assessment.id));
    }
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const addChangeDetailToChangesHistory =
  (changeDetail: ChangeDetail) =>
  async (dispatch: any, state: any): Promise<void> => {
    const assessment = state().assessmentDetail.value;
    if (assessment) {
      const newChangesHistory = [changeDetail, ...assessment.changesHistory];
      const newAssessment = { ...assessment, changesHistory: newChangesHistory };
      dispatch(change(newAssessment));
    }
  };

export const updateAuditors =
  (id: string, auditors: Auditor[]) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateAuditors(id, auditors);
      dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateYearAndMonth =
  (id: string, year: number, month: number) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateYearAndMonth(id, year, month);
      dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateDistributionList =
  (id: string, distributionList: string[]) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateDistributionList(id, distributionList);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateReadOnlyDistributionList =
  (id: string, readOnlyDistributionList: string[]) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateReadOnlyDistributionList(id, readOnlyDistributionList);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateStatus =
  (id: string, status: StatusCode) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateStatus(id, status);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const createComment =
  (id: string, content: string) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.createComment(id, content);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateComment =
  (id: string, comment: Comment) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateComment(id, comment);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const createTopicComment =
  (topicId: string, message: string) =>
  async (dispatch: any, state: any): Promise<TopicForDiscussion | null> => {
    try {
      const response = await api.createTopicComment(state().assessmentDetail.value.id, topicId, message);
      await dispatch(change(response.assessment));
      return response.topic;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateTopicComment =
  (topicId: string, commentId: string, message: string) =>
  async (dispatch: any, state: any): Promise<TopicForDiscussion | null> => {
    try {
      const response = await api.updateTopicComment(state().assessmentDetail.value.id, topicId, commentId, message);
      await dispatch(change(response.assessment));
      return response.topic;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateOverview =
  (id: string, value: string) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateOverview(id, value);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateStoreManagerSince =
  (id: string, storeManagerSince: string) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateStoreManagerSince(id, storeManagerSince);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateStoreManager =
  (id: string, storeManager: { email: string; name: string; storeManagerNotAvailable: boolean }) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateStoreManager(id, storeManager);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateStoreManagerAssistant =
  (id: string, storeManagerAssistant: { email: string; name: string }) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateStoreManagerAssistant(id, storeManagerAssistant);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateEmployees =
  (id: string, employees: number) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateEmployees(id, employees);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateRetailAreaManagerEmail =
  (id: string, retailAreaManager: { name: string; email: string }) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateRetailAreaManager(id, retailAreaManager);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateBrandCoordinatorList =
  (id: string, brandCoordinators: BrandCoordinator[]) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const request: UpdateBrandCoordinatorRequest[] = brandCoordinators.map(bc => ({
        role: bc.role,
        email: bc.email,
      }));
      const assessment = await api.updateBrandCoordinatorList(id, request);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateRetailProcedure =
  (id: string, retailProcedure: boolean) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateRetailProcedure(id, retailProcedure);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const updateEntityAudit =
  (id: string, entityAudit: boolean) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateEntityAudit(id, entityAudit);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const saveSubProcessResult =
  (request: ResultUpdateRequest) =>
  async (dispatch: any, state: () => any): Promise<Assessment | null> => {
    try {
      const assessment = await api.saveSubProcessResult(state().assessmentDetail.value.id, request);
      await dispatch(change(assessment));
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const submitActionPlan =
  (request: ActionPlanSubmitRequest) =>
  async (dispatch: any, state: () => any): Promise<Assessment | null> => {
    try {
      const assessment = await api.submitActionPlan(state().assessmentDetail.value.id, request);
      await dispatch(change(assessment));
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const saveActionPlanAttachmentsChecked =
  (request: ActionPlanAttachmentsCheckedRequest) =>
  async (dispatch: any, state: () => any): Promise<Assessment | null> => {
    try {
      const assessment = await api.saveActionPlanAttachmentsChecked(state().assessmentDetail.value.id, request);
      await dispatch(change(assessment));
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const saveFollowUpAttachmentsChecked =
  (request: FollowUpAttachmentsCheckedRequest) =>
  async (dispatch: any, state: () => any): Promise<Assessment | null> => {
    try {
      const assessment = await api.saveFollowUpAttachmentsChecked(state().assessmentDetail.value.id, request);
      await dispatch(change(assessment));
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const selectAssessment = (state: any) => state.assessmentDetail.value as AssessmentDetail | null;
export const selectIsFetchingAssessment = (state: any) => state.assessmentDetail.isFetching as boolean;

export const selectTopics = (state: any): TopicForDiscussion[] =>
  state.assessmentDetail.value.audit.topicsForDiscussion.map(
    (topic: TopicForDiscussion) => topic as TopicForDiscussion
  );

export const selectIsAuditorOwner = createSelector(selectPrincipal, selectAssessment, (user, assessmentDetail) => {
  const assessment = assessmentDetail;
  return (
    checkRole(user, ROLES.AUDITOR) &&
    assessment != null &&
    assessment.auditors.find(a => a.email.toLowerCase() === user.email.toLowerCase()) != null
  );
});

export const selectRegionalManager = (state: RootState): Contact | undefined =>
  state.assessmentDetail?.value?.regionalManager;

export const selectAssessmentStatus = createSelector(selectAssessment, assessmentDetail =>
  assessmentDetail != null ? (assessmentDetail as AssessmentDetail).status : null
);

export const selectCanManagerCreateOrEditTopic = createSelector(
  selectPrincipal,
  selectAssessmentStatus,
  (user, status) => {
    return checkRole(user, ROLES.AUDITOR_MANAGER) && STATUS.UNDER_REVIEW.code === status?.code;
  }
);

export const selectCanCreateOrEditTopicAsRegionalManager = createSelector(
  selectPrincipal,
  selectAssessmentStatus,
  selectRegionalManager,
  (user, status, regionalManager) => {
    return (
      regionalManager != null &&
      regionalManager.email === user.email &&
      checkRole(user, ROLES.AUDITOR) &&
      STATUS.UNDER_REVIEW_BY_REGIONAL_MANAGER.code === status?.code
    );
  }
);

export const selectCanCreateOrEditTopicAsAuditorOwner = createSelector(
  selectPrincipal,
  selectAssessmentStatus,
  selectIsAuditorOwner,
  (user, status, isAuditorOwner) => {
    return (
      checkRole(user, ROLES.AUDITOR) &&
      isAuditorOwner &&
      (STATUS.MERGING.code === status?.code || STATUS.IN_PROGRESS.code === status?.code)
    );
  }
);

export const selectCanCRUDTopic = createSelector(
  selectPrincipal,
  selectIsAuditorOwner,
  selectCanCreateOrEditTopicAsRegionalManager,
  selectCanCreateOrEditTopicAsAuditorOwner,
  (user, isAuditorOwner, canCreateOrEditTopicAsRegionalManager, canCreateOrEditTopicAsAuditorOwner) => {
    return (
      checkRole(user, ROLES.AUDIT_ADMIN) || canCreateOrEditTopicAsRegionalManager || canCreateOrEditTopicAsAuditorOwner
    );
  }
);

export const selectAssessmentTabs = createSelector(selectAssessmentStatus, selectPrincipal, (status, user) => {
  if (checkRoles(user, AUDITOR_STAFF_ROLES)) {
    if (status != null && status.code !== STATUS.NEW.code && status.code !== STATUS.IN_PROGRESS.code) {
      return ['assessment.tab.assessment', 'assessment.tab.audit', 'assessment.tab.summary', 'assessment.tab.history'];
    }
    return ['assessment.tab.assessment', 'assessment.tab.audit', 'assessment.tab.history'];
  }
  return ['assessment.tab.assessment', 'assessment.tab.audit'];
});

export const selectDistributionList = createSelector(selectAssessment, assessmentDetail =>
  assessmentDetail != null ? (assessmentDetail as AssessmentDetail).distributionList : []
);

export const selectReadOnlyDistributionList = createSelector(selectAssessment, assessmentDetail =>
  assessmentDetail != null ? (assessmentDetail as AssessmentDetail).readOnlyDistributionList : []
);

export const selectBrandCoordinatorList = createSelector(selectAssessment, assessmentDetail =>
  assessmentDetail != null ? (assessmentDetail as AssessmentDetail).brandCoordinatorList : []
);

export const selectIsBrandCoordinator = createSelector(
  selectPrincipal,
  selectBrandCoordinatorList,
  (user, brandCoordinatorList) => {
    return (
      checkRole(user, ROLES.BASIC_USER) &&
      brandCoordinatorList != null &&
      brandCoordinatorList.some(bc => bc.email.toLowerCase() === user.email.toLowerCase())
    );
  }
);

export const selectIsActionPlanEditor = createSelector(selectPrincipal, selectAssessment, (user, assessmentDetail) => {
  return assessmentDetail?.audit?.processes.some(p =>
    p.subProcesses.some(sp => sp.actionPlan?.editor?.email != null && sp.actionPlan.editor.email === user.email)
  );
});

export const selectIsEditStoreUser = createSelector(
  selectPrincipal,
  selectDistributionList,
  selectBrandCoordinatorList,
  (user, distributionList, brandCoordinatorList) => {
    return (
      checkRole(user, ROLES.BASIC_USER) &&
      (distributionList.find(element => element.email.toLowerCase() === user.email.toLowerCase()) != null ||
        (brandCoordinatorList != null &&
          brandCoordinatorList.some(bc => bc.email.toLowerCase() === user.email.toLowerCase())))
    );
  }
);

export const selectIsReadOnlyStoreUser = createSelector(
  selectPrincipal,
  selectDistributionList,
  selectReadOnlyDistributionList,
  selectBrandCoordinatorList,
  (user, distributionList, readOnlyDistributionList, brandCoordinatorList) => {
    return (
      checkRole(user, ROLES.BASIC_USER) &&
      (distributionList.find(element => element.email.toLowerCase() === user.email.toLowerCase()) != null ||
        readOnlyDistributionList.find(element => element.email.toLowerCase() === user.email.toLowerCase()) != null ||
        (brandCoordinatorList != null &&
          brandCoordinatorList.some(bc => bc.email.toLowerCase() === user.email.toLowerCase())))
    );
  }
);

export const selectIsInDistributionList = createSelector(
  selectPrincipal,
  selectDistributionList,
  (user, distributionList) =>
    checkRole(user, ROLES.BASIC_USER) &&
    distributionList.find(element => element.email.toLowerCase() === user.email.toLowerCase()) != null
);

export const selectIsInReadOnlyDistributionList = createSelector(
  selectPrincipal,
  selectReadOnlyDistributionList,
  (user, readOnlyDistributionList) =>
    checkRole(user, ROLES.BASIC_USER) &&
    readOnlyDistributionList.find(element => element.email.toLowerCase() === user.email.toLowerCase()) != null
);

export const selectCanWriteTopicComment = createSelector(
  selectAssessmentStatus,
  selectIsInDistributionList,
  selectIsBrandCoordinator,
  selectIsAuditorOwner,
  selectPrincipal,
  selectRegionalManager,
  (status, isInDistributionList, isBrandCoordinator, isAuditorOwner, principal, regionalManager): boolean => {
    return (
      checkRole(principal, ROLES.AUDIT_ADMIN) ||
      ((status?.code === STATUS.MERGING.code || status?.code === STATUS.IN_PROGRESS.code) && isAuditorOwner) ||
      (status?.code === STATUS.SENT_TO_THE_STORES.code && (isBrandCoordinator || isInDistributionList)) ||
      (status?.code === STATUS.UNDER_REVIEW_BY_REGIONAL_MANAGER.code && regionalManager?.email === principal.email)
    );
  }
);

export const selectIsSentToTheStoreStatus = createSelector(selectAssessmentStatus, (status): boolean => {
  return status?.code === STATUS.SENT_TO_THE_STORES.code;
});

export const selectAssessmentBrand = (state: RootState): BrandCode =>
  state.assessmentDetail?.value?.store.brand.code as BrandCode;

export const saveAuditManagerNoteAction =
  (request: SubProcessNoteRequest) =>
  async (dispatch: any, state: () => any): Promise<Assessment | null> => {
    try {
      const assessment = await api.saveAuditManagerNote(state().assessmentDetail.value.id, request);
      await dispatch(change(assessment));
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const editTopicForDiscussion =
  (topicForDiscussionRequest: TopicForDiscussionRequest) =>
  async (dispatch: any, state: () => any): Promise<TopicForDiscussion | null> => {
    try {
      const topicForDiscussioneUpsertResponse = await api.editTopicForDiscussion(
        state().assessmentDetail.value.id,
        topicForDiscussionRequest
      );

      await dispatch(change(topicForDiscussioneUpsertResponse.assessment));
      return topicForDiscussioneUpsertResponse.topic;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const deleteTopicForDiscussion =
  (topicForDiscussionId: string) =>
  async (dispatch: any, state: () => any): Promise<Assessment | null> => {
    try {
      const assessment = await api.deleteTopicForDiscussion(state().assessmentDetail.value.id, topicForDiscussionId);

      await dispatch(change(assessment));
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const createTopicForDiscussion =
  (title: string) =>
  async (dispatch: any, state: () => any): Promise<TopicForDiscussion | null> => {
    try {
      const topicForDiscussioneUpsertResponse = await api.createTopicForDiscussion(state().assessmentDetail.value.id, {
        title,
      } as TopicForDiscussionUpsertRequest);

      await dispatch(change(topicForDiscussioneUpsertResponse.assessment));
      return topicForDiscussioneUpsertResponse.topic;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const saveRegionalManagerNoteAction =
  (request: SubProcessNoteRequest) =>
  async (dispatch: any, state: () => any): Promise<Assessment | null> => {
    try {
      const assessment = await api.saveRegionalManagerNote(state().assessmentDetail.value.id, request);
      await dispatch(change(assessment));
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const saveActionPlan =
  (request: ActionPlanUpdateRequest) =>
  async (dispatch: any, state: () => any): Promise<Assessment | null> => {
    try {
      const assessment = await api.saveActionPlan(state().assessmentDetail.value.id, request);
      await dispatch(change(assessment));
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const savePlanAuditorNoteAction =
  (request: SubProcessNoteRequest): AppThunk =>
  async (dispatch: any, state: () => any): Promise<Assessment | null> => {
    try {
      const assessment = await api.saveActionPlanAuditorNote(state().assessmentDetail.value.id, request);
      await dispatch(change(assessment));
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const saveActionPlanBrandNote =
  (request: SubProcessNoteRequest): AppThunk =>
  async (dispatch: any, state: () => any): Promise<Assessment | null> => {
    try {
      const assessment = await api.saveActionPlanBrandNote(state().assessmentDetail.value.id, request);
      dispatch(change(assessment));
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const assignActionPlanEditor =
  (request: ActionPlanEditorUpdateRequest) =>
  async (dispatch: any, state: () => any): Promise<Assessment | null> => {
    try {
      const assessment = await api.saveActionPlanEditor(state().assessmentDetail.value.id, request);
      await dispatch(change(assessment));
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const updateFollowUpStatus =
  (assessmentId: string, status: FollowUpStatus, oldStatus: FollowUpStatus) =>
  async (dispatch: any, state: any): Promise<AssessmentDetail | null> => {
    try {
      const assessment = await api.updateFollowUpStatus(assessmentId, status, oldStatus);
      await dispatch(change(assessment));
      return assessment;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      return null;
    }
  };

export const saveFollowUp =
  (request: FollowUpUpdateRequest) =>
  async (dispatch: any, state: () => any): Promise<Assessment | null> => {
    try {
      const assessment = await api.saveFollowUp(state().assessmentDetail.value.id, request);
      await dispatch(change(assessment));
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const submitFollowUp =
  (request: FollowUpSubmitRequest) =>
  async (dispatch: any, state: () => any): Promise<Assessment | null> => {
    try {
      const assessment = await api.submitFollowUp(state().assessmentDetail.value.id, request);
      await dispatch(change(assessment));
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export default assessmentDetailSlice.reducer;
